import classNames from 'classnames';
import * as React from 'react';
import styles from './loader.module.scss';

export const QuickLoader = ({ size = 40, className = '' }) => (
  <span
    className={classNames(styles.spinner, className)}
    style={{ height: size, width: size }}
  ></span>
);

const Loader = () => {
  return (
    <div className={styles.loader_overlay}>
      <div className={styles.loader_overlay_inner}>
        <div className={styles.loader_overlay_content}>
          <QuickLoader />
        </div>
      </div>
    </div>
  );
};

export default Loader;

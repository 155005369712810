import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: null,
  email: null,
  is_virtual: null,
  applied_coupons: null,
  available_payment_methods: [],
  selected_payment_method: null,
  billing_address: null,
  shipping_addresses: [],
  items: null,
  prices: {
    applied_taxes: null,
    discounts: null,
    grand_total: null,
    subtotal_excluding_tax: null,
    subtotal_including_tax: null,
    subtotal_with_discount_excluding_tax: null,
  },
  loading: false,
  loaded: false,
  note: null,
  updatingCartItem: null,
};

export const cart = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    loading: (state, action) => {
      state = {
        ...state,
        loaded: false,
        loading: true,
        ...action.payload,
      };

      return state;
    },
    reload: (state, action) => {
      state = {
        updatingCartItem: null,
        ...state,
        ...action.payload,
        loaded: true,
        loading: false,
      };

      return state;
    },
    reset: (state) => {
      state = {
        ...initialState,
        loaded: true,
        loading: false,
      };
      return state;
    },
  },
});

//Export Actions
export const { reload, reset, loading } = cart.actions;

export const selectCart = (state: any) => ({ ...state.cart });

export default cart.reducer;

import { createContext, useContext, useMemo, useState } from 'react';

interface HeaderContextState {
	showMenu?: boolean;
	showMiniBag?: boolean;
}
interface HeaderContextApi {
	handleMenuVisibility?: any;
	toggleMiniBag?: any;
}

type HeaderContextValue = [HeaderContextState, HeaderContextApi];

export const HeaderContext = createContext<HeaderContextValue>([{}, {}]);

const HeaderProvider = ({ children }) => {
	const [showMenu, handleMenuVisibility] = useState(false);
	const [showMiniBag, toggleMiniBag] = useState(false);

	const contextValue: HeaderContextValue = useMemo(() => {
		return [{ showMenu, showMiniBag }, { handleMenuVisibility, toggleMiniBag }];
	}, []);

	return <HeaderContext.Provider value={contextValue}>{children}</HeaderContext.Provider>;
};

export const useHeader = () => useContext(HeaderContext);
export default HeaderProvider;

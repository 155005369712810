import { combineReducers, configureStore } from '@reduxjs/toolkit';
import auth from './reducers/auth.reducer';
import cart from './reducers/cart.reducer';

// export type RootState = ReturnType<typeof rootReducer>;
// if (process.env.NODE_ENV === 'development' && module.hot) {
//   module['hot'].accept('./rootReducer', () => {
//     const newRootReducer = require('./rootReducer').default;
//     store.replaceReducer(newRootReducer);
//   });
// }
const createStore = () => {
  const reducer = combineReducers({
    auth,
    cart
  });
  const store = configureStore({
    reducer,
  });
  return store;
};

export default createStore;

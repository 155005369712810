import gql from 'graphql-tag';
import BundleProductFragment from '@/services/graphql/queries/Product.gql//BundleProductFragment.gql';
import ConfigurableProductFragment from '@/services/graphql/queries/Product.gql/ConfigurableProductFragment.gql';
import { ProductDetailsFragment } from '@/services/graphql/queries/Product.gql/ProductDetailsFragment.gql';

export const GET_CUSTOMER = gql`
  ${ProductDetailsFragment}
  ${BundleProductFragment}
  ${ConfigurableProductFragment}

  query getCustomer {
    customer {
      firstname
      lastname
      mobilenumber
      suffix
      id
      created_at
      email
      isEmailAvailable
      wishlist {
        id
        items_count
        items_v2(pageSize: 100) {
          items {
            id
            quantity
            product {
              __typename
              ...ProductDetailsFragment
              ...BundleProductFragment
              ...ConfigurableProductFragment
            }
          }
        }
      }
      customer_notifications {
        notification_offers
        notification_products_reminder
        notification_launches
        neevtalks_reminder
      }
      addresses {
        firstname
        lastname
        middlename
        street
        city
        suffix
        company
        region {
          region_code
          region_id
          region
        }
        custom_attributes {
          attribute_code
          value
        }
        fax
        telephone
        id
        vat_id
        customer_id
        postcode
        prefix
        country_code
        telephone
        default_billing
        default_shipping
      }
    }
    customerCart {
      id
    }
  }
`;

export const REMOVE_ADDRESS_MUTATION = gql`
  mutation deleteCustomerAddress($id: Int!) {
    deleteCustomerAddress(id: $id)
  }
`;

export const CREATE_ADDRESS_MUTATION = gql`
  mutation createCustomerAddress($customerAddress: CustomerAddressInput!) {
    createCustomerAddress(input: $customerAddress) {
      id
      firstname
      lastname
      region {
        region
        region_code
      }
      country_code
      street
      telephone
      postcode
      city
      default_shipping
      default_billing
    }
  }
`;

export const UPDATE_ADDRESS_MUTATION = gql`
  mutation updateCustomerAddress(
    $id: Int!
    $customerAddress: CustomerAddressInput!
  ) {
    updateCustomerAddress(id: $id, input: $customerAddress) {
      id
      firstname
      lastname
      region {
        region
        region_code
      }
      country_code
      street
      telephone
      postcode
      city
      default_shipping
      default_billing
    }
  }
`;

export const REMOVE_PRODUCT_FROM_WISHLIST = gql`
  ${ProductDetailsFragment}
  ${BundleProductFragment}
  ${ConfigurableProductFragment}

  mutation removeProductsFromWishlist($wishlistId: ID!, $wishlistItemId: ID!) {
    removeProductsFromWishlist(
      wishlistId: $wishlistId
      wishlistItemsIds: [$wishlistItemId]
    ) {
      wishlist {
        id
        items_count
        items_v2 {
          items {
            id
            quantity
            product {
              ...ProductDetailsFragment
              ...BundleProductFragment
              ...ConfigurableProductFragment
            }
          }
        }
      }
      user_errors {
        code
        message
      }
    }
  }
`;

export const RESET_PASSWORD_MUTATION = gql`
  mutation resetPassword(
    $email: String!
    $resetPasswordToken: String!
    $newPassword: String!
  ) {
    resetPassword(
      email: $email
      resetPasswordToken: $resetPasswordToken
      newPassword: $newPassword
    )
  }
`;

export default {
  queries: {},
  mutations: {
    resetPasswordMutation: RESET_PASSWORD_MUTATION,
    removeAddressMutation: REMOVE_ADDRESS_MUTATION,
    createAddressMutation: CREATE_ADDRESS_MUTATION,
    updateAddressMutation: UPDATE_ADDRESS_MUTATION,
    removeProductFromWishlistMutation: REMOVE_PRODUCT_FROM_WISHLIST,
  },
};

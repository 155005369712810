import cn from 'classnames';
import { useFormContext } from 'react-hook-form';
import Form from '@/components/atoms/Form';
import Error from '@/components/atoms/Form/components/Error';
import SvgIcon from '@/components/atoms/icon/SvgIcon';
import get from 'lodash/get';

export default function InputField(props) {
  const {
    label,
    name,
    size,
    rules = {},
    inputType,
    defaultClass = false,
    className,
  } = props;
  const { register, errors } = useFormContext();
  const formType = inputType || 'Input';
  const Comps = Form[formType];
  const error = get(errors, name);

  return (
    <div>
      {label && <label htmlFor={name}>{label}</label>}
      <Comps
        name={name}
        {...props}
        id={`#${name}`}
        getRef={register(rules)}
        className={cn({ 'input-field': !defaultClass }, className)}
      />
      {error?.message && <Error message={error.message} />}
    </div>
  );
}

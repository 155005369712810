import Columns from '@/components/atoms/Columns';
import Container from '@/components/atoms/Container';
import Image from '@/components/atoms/Image';
import { useAppContext } from '@/components/context/AppContext';
import { useCartContext } from '@/components/context/CartContext';
import { useCallback, useMemo, useState } from 'react';
import styles from '../checkout.module.scss';
import item from './item.module.scss';

import cn from 'classnames';
import { CartItemPrice } from './CartItemPrice';
import { CartItemMeta } from './CartItemMeta';

const Item = ({
  product,
  quantity,
  prices,
  counter = false,
  uid,
  configurable_options,
  bundle_options,
  summary = false,
  closePopups = null,
  am_giftcard_options,
}) => {
  const giftCardOptions = useMemo(
    () =>
      Array.isArray(am_giftcard_options)
        ? am_giftcard_options.filter((e: any) => {
            return (
              [
                'am_giftcard_recipient_email',
                'am_giftcard_recipient_name',
                'am_giftcard_recipient_number',
              ].indexOf(e.code) > -1
            );
          })
        : [],
    [am_giftcard_options],
  );

  const [
    { isCartLoading },
    { handleRemoveCartItem, handleUpdateCartItem },
  ]: any = useCartContext();
  const [gift, toggle] = useState(false);
  const [{ isMobile, isDesktop }] = useAppContext();
  const [isCartItemUpdating, setLoader] = useState(false);

  const {
    selectedVariant,
    attributes,
    editProduct = false,
  } = useMemo(() => {
    if (isCartLoading) return {};

    if (product.__typename === 'BundleProduct') {
      const selectedBundle = bundle_options.filter(
        (e: any) => e.label === 'Color',
      )[0];
      const selectBundleValue = selectedBundle?.values?.[0] || {};
      let selectedVariant: any = {
        product,
        bundleOptions: [],
      };

      if (selectBundleValue?.uid) {
        selectedVariant = product.bundle_items?.reduce(
          (a: any, e: any) => {
            if (e.uid === selectedBundle?.uid) {
              const bundleVariant = e.options.filter(
                (x: any) => x.uid === selectBundleValue.uid,
              );

              return {
                product: bundleVariant?.[0]?.product,
                fabricLength: selectedBundle?.values?.[0]?.quantity,
              };
            }

            return a;
          },
          {
            product,
          },
        );

        const matchingFabric =
          bundle_options.filter((e: any) => e.label === 'Matching Fabric')[0]
            ?.values || [];

        //Commented temporary
        if (Array.isArray(matchingFabric) || selectedVariant?.fabricLength) {
          selectedVariant.fabricProduct = true;
          selectedVariant.bundleOptions = [
            { label: 'Length', quantity: selectedVariant?.fabricLength },
            ...matchingFabric,
          ]
            .map(
              ({ label, quantity: qty }: any) =>
                !!qty && {
                  label,
                  qty,
                },
            )
            .filter(Boolean);
        }
      } else {
        selectedVariant.bundleOptions = bundle_options.map(
          ({ label, values = [] }: any) => ({
            label,
            qty: values?.[0]?.quantity,
          }),
        );
      }

      return {
        editProduct: true,
        selectedVariant,
        attributes: {
          value_label: selectBundleValue.label,
        },
      };
    } else {
      const variants = product?.variants || [];
      const d = variants.reduce(
        (a: any, e: any) => {
          const attributes = configurable_options?.[0];
          const variant = e?.attributes?.[0];
          if (
            attributes?.configurable_product_option_value_uid === variant?.uid
          )
            return { selectedVariant: e, attributes };
          return a;
        },
        {
          selectedVariant: {
            product,
          },
          attributes: {},
        },
      );
      return d;
    }
  }, [product, configurable_options]);

  const updateCartItem = useCallback(
    async (e: number) => {
      try {
        if (!e) return;

        setLoader(true);
        await handleUpdateCartItem(
          [
            {
              quantity: e,
              cart_item_uid: uid,
            },
          ],
          false,
        );
        setLoader(false);
      } catch {
        setLoader(false);
      }
    },
    [uid],
  );

  return (
    <Container className={cn(styles.cartItem, 'mb-4 p-3')}>
      <Columns multiline breakpoint={'mobile'} className={'pb-0 my-0'}>
        <Columns.Column
          size={'3'}
          className={cn(isMobile && 'pl-0', 'p-0', !isMobile && 'pl-2')}
        >
          <Image
            style={{ height: '100%' }}
            src={selectedVariant?.product?.thumbnail.url}
            alt={selectedVariant?.product.name}
            fit={'cover'}
            size={'4by5'}
          />
        </Columns.Column>

        <Columns.Column
          className='py-0 is-flex is-flex-direction-column'
          size={'9'}
        >
          <Columns className={item.root}>
            <CartItemMeta
              {...{
                closePopups,
                quantity,
                attributes,
                editProduct,
                product,
                selectedVariant,
                uid,
                updateCartItem,
                isCartItemUpdating,
                summary,
                giftCardOptions,
              }}
            />
            <CartItemPrice
              {...{
                quantity,
                prices,
                uid,
                product,
                handleRemoveCartItem,
                summary,
              }}
            />
          </Columns>
        </Columns.Column>
      </Columns>
    </Container>
  );
};

export default Item;

import ItemProductFragement from '@/services/graphql/queries/Product.gql/BundleProduct/ItemProductFragement.gql';
import MediaGallery from '@/services/graphql/fragments/product/MediaGallery.gql';
import ProductPrice from '@/services/graphql/fragments/product/ProductPrice.gql';
import { gql } from 'graphql-request';

const BundleProductFragment = gql`
fragment BundleProductFragment on BundleProduct {
    dynamic_sku
    dynamic_price
    dynamic_weight
    price_view
    ship_bundle_items
    bundle_items : items {
      uid
      title
      sku
      type
      position
      required
      options {
        is_default
        can_change_quantity
        label
        position
        price
        price_type
        quantity
        uid
        product {
          uid
          price_per_metre
          length
          width
          fabric
          dimensions
          work
          care
          color
          dispatch_time
          core_meta:dynamicAttributes(fields: ["fabric","work", "dispatch_time"])
          qty_range
          special_price
          sku
          name
          thumbnail {
            url
          }
          url_key
          price_range {
            minimum_price ${ProductPrice()}
            maximum_price ${ProductPrice()}
          }
          ${ItemProductFragement}
          stock_status
        }
      }
    }
  }
`;

export default BundleProductFragment;

import { NavItem } from './NavItem';
import cn from 'classnames';
import styles from './HeaderMenu.module.scss';

export const DesktopMenu = ({
  categories = [],
  active,
  handleMenuVisibility,
}) => {
  return (
    <div className='menu-list mt-2'>
      {[...categories].map((c: any, i) => {
        return (
          <NavItem
            onClick={() => handleMenuVisibility(false)}
            className={cn(
              {
                ['is-active']: i === active,
              },
              styles['active-section'],
              'is-size-3 family-ddin-regular is-uppercase has-text-weight-medium mb-4 pl-5 is-flex',
              styles.level1,
            )}
            {...{
              c,
              key: i,
              url: `/${c.url_path}${c.url_suffix}`,
              l1: true,
            }}
          >
            {Array.isArray(c.children) && !!c.children.length && (
              <section style={{ maxHeight: 'initial' }}>
                {c.children.map((c: any, j: any) => (
                  <NavItem
                    onClick={() => handleMenuVisibility(false)}
                    icon
                    className={'ternary-color is-size-6 is-flex py-3'}
                    {...{
                      c,
                      key: j,
                      url: `/${c.url_path}${c.url_suffix}`,
                      l2: true,
                    }}
                  >
                    <section>
                      {c.children &&
                        c.children.map((c, k) => {
                          return (
                            <NavItem
                              onClick={() => handleMenuVisibility(false)}
                              className={
                                'ternary-color is-size-6 is-flex py-3 pl-5'
                              }
                              {...{
                                c,
                                key: k,
                                url: `/${c.url_path}${c.url_suffix}`,
                                l3: true,
                              }}
                            />
                          );
                        })}
                    </section>
                  </NavItem>
                ))}
              </section>
            )}
          </NavItem>
        );
      })}
    </div>
  );
};

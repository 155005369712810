import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Button from '../../atoms/Button';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Form from '@/components/atoms/Form';
import Icon from '@/components/atoms/Icon';
import cn from 'classnames';
import styles from './styles.module.scss';
import { memo } from 'react';

const CounterView = (props: any) => {
  const {
    onChange,
    onBlur,
    value,
    name,
    ghostBorder,
    className,
    preventZeroQty = false,
  } = props;
  const color = props.size == 'small' ? 'ghost' : null;
  return (
    <Form.Field className={cn('mb-0', className)}>
      <Form.Control iconLeft iconRight>
        <Form.Input
          isSize={props.size}
          isColor={color}
          onChange={(e) => {
            // It is an synthetic event e.target is not good. It will be fixed in React 17
            let qty = +e.currentTarget.value;
            qty = qty > 99 ? 99 : qty;
            if (!isNaN(qty) && qty > -1) {
              onChange && onChange(+qty);
            }
          }}
          onBlur={(e) => {
            let qty = +e.currentTarget.value;
            qty = qty > 99 ? 99 : qty;

            if (qty <= 0) {
              onChange && onChange(1);
            }
            onBlur && onBlur(qty);
          }}
          // getRef={register}
          size={3}
          className={cn('has-text-centered', {
            [styles['ghost-border']]: ghostBorder,
          })}
          name={props.name}
          value={value}
        />
        <Icon
          size={props.size}
          align='left'
          className='is-clickable'
          onClick={(e) => {
            const limit = preventZeroQty ? 1 : 0;
            if (!isNaN(value) && value > limit) {
              onChange && onChange(+value - 1);
            }
          }}
        >
          <FontAwesomeIcon icon={faMinus} />
        </Icon>
        <Icon
          size={props.size}
          align='right'
          className='is-clickable'
          onClick={(e) => {
            // const quantity = getValues(props.name) || 1;
            // setValue(props.name, +quantity + 1);
            const v = +value + 1;
            onChange && onChange(v > 99 ? 99 : v);
          }}
        >
          <FontAwesomeIcon icon={faPlus} />
        </Icon>
      </Form.Control>
    </Form.Field>
  );
};

const Counter = (props) => {
  const {
    defaultValue = 1,
    name,
    ghostBorder = false,
    className,
    preventZeroQty = false,
    onBlur,
  } = props;
  const { control } = useFormContext();

  return (
    <Controller
      render={({ onChange, ref, ...rest }) => (
        <CounterView
          {...rest}
          ghostBorder={ghostBorder}
          preventZeroQty={preventZeroQty}
          size={props.size}
          className={className}
          defaultValue={defaultValue}
          onChange={(val) => {
            props.onChange && props.onChange(val);
            onChange(val);
          }}
          name={name}
          onBlur={(val) => {
            props.onBlur && props.onBlur(val);
          }}
        />
      )}
      name={name}
      defaultValue={defaultValue}
      control={control}
    />
  );
};

export default Counter;

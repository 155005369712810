import { gql } from 'graphql-request';
import { useCallback, useEffect, useState } from 'react';
import { webClient } from '@/services/graphql/clients/web.client';
import { getCurrencyHeaders } from '@/services/storage/currency';
import { DocumentNode } from 'graphql';
import { useGiftCard } from '@/hooks/cart/useGiftCard';

interface IUseGraphQLRequest {
  (args: {
    method?: 'post' | 'get' | 'put' | 'delete' | 'patch';
    query: DocumentNode | string;
    immediate?: boolean;
    config?: any;
    initialData?: any;
    client?: any;
    transformData?: (d: any) => any;
  }): [
    (data?: any, config?: any) => Promise<any>,
    {
      data: any;
      error: any;
      loading: boolean;
    },
  ];
}

const useGraphQLRequest: IUseGraphQLRequest = ({
  method = 'post',
  query,
  config = {},
  immediate,
  initialData,
  client = webClient,
  transformData = (d) => d,
}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(initialData || null);
  const [error, setError] = useState(null);

  const fetchQuery = useCallback(
    (params = {}, paramsConfig = {}) => {
      //set currency selected in header menu.
      config.headers = {
        ...getCurrencyHeaders().headers,
        ...config?.headers,
        ...paramsConfig?.headers,
      };
      params = {
        ...config.params,
        ...params,
      };

      delete config.params;

      setLoading(true);
      setData(null);
      setError(null);
      return client(config)
        .request(query, params)
        .then((d) => {
          setData(transformData(d));
          return d;
        })
        .catch((e) => {
          if (e.response.data) setData(e.response.data);
          setError(e);
          return e;
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [method, query, config],
  );

  useEffect(() => {
    if (immediate) {
      fetchQuery();
    }
  }, [immediate]);

  return [fetchQuery, { loading, data, error }];
};

export default useGraphQLRequest;

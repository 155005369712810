import { CartPricesFragment } from '@/services/graphql/fragments/cart/cart.gql';
import { ProductListFragment } from '@/services/graphql/fragments/product/productList.gql';
import gql from 'graphql-tag';

export const REMOVE_GIFT_CARD = gql`
  ${CartPricesFragment}

  mutation removeAmGiftCardFromCart(
    $cart_id: String!
    $am_gift_card_code: String!
    $cartPrices: Boolean!
  ) {
    removeAmGiftCardFromCart(
      input: { cart_id: $cart_id, am_gift_card_code: $am_gift_card_code }
    ) {
      cart {
        ...CartPricesFragment
        redeem_gift_card

        available_payment_methods {
          code
          title
        }

        applied_am_gift_cards {
          applied_balance {
            currency
            value
          }
          code
          current_balance {
            currency
            value
          }
          expiration_date
        }
      }
    }
  }
`;

export const APPLY_GIFT_CARD = gql`
  ${CartPricesFragment}

  mutation applyAmGiftCardToCart(
    $cart_id: String!
    $am_gift_card_code: String!
    $cartPrices: Boolean!
  ) {
    applyAmGiftCardToCart(
      input: { cart_id: $cart_id, am_gift_card_code: $am_gift_card_code }
    ) {
      cart {
        ...CartPricesFragment
        redeem_gift_card

        available_payment_methods {
          code
          title
        }

        applied_am_gift_cards {
          applied_balance {
            currency
            value
          }
          code
          msg
          current_balance {
            currency
            value
          }
          expiration_date
        }
      }
    }
  }
`;

export const ADD_GIFT_CARD_PRODUCTS_TO_CART = gql`
  ${ProductListFragment}
  ${CartPricesFragment}

  mutation addAmGiftCardProductsToCart(
    $cart_id: String!
    $sku: String!
    $quantity: Float!
    $amount: Float!
    $sender_name: String!
    $recipient_name: String!
    $recipient_email: String!
    $mobile: String!
    $message: String!
    $date_delivery: String
    $date_delivery_timezone: String
    $cartPrices: Boolean!
  ) {
    addAmGiftCardProductsToCart(
      input: {
        cart_id: $cart_id
        cart_items: [
          {
            data: { quantity: $quantity, sku: $sku }
            gift_card_options: {
              am_giftcard_amount_custom: $amount
              am_giftcard_sender_name: $sender_name
              am_giftcard_recipient_name: $recipient_name
              am_giftcard_recipient_email: $recipient_email
              am_giftcard_recipient_number: $mobile
              am_giftcard_message: $message
              am_giftcard_date_delivery: $date_delivery
              am_giftcard_date_delivery_timezone: $date_delivery_timezone
              is_date_delivery: true
              am_giftcard_image: 1
              am_giftcard_type: 1
            }
          }
        ]
      }
    ) {
      cart {
        redeem_gift_card
        ...CartPricesFragment
        ...ProductListFragment
      }
    }
  }
`;

export const GET_GIFT_CARD_DETAILS = gql`
  query getGiftCardDetails($code: String!) {
    amGiftCardAccount(input: { am_gift_card_code: $code }) {
      code
      current_balance {
        currency
        value
      }
      initial_value {
        currency
        value
      }
      expiration_date
      status
    }
  }
`;

export const GET_GIFT_CARD_DETAILS_ACCOUNT = `
query getGiftCardDetails($number: String!){
  GiftCardsAccount(input: {am_giftcard_recipient_number : $number}){
    data
    {
      code
      name
      message
      current_balance
      expiration_date
      initial_value
      status

    }
  }
}
`;

export const GET_CREDIT_NOTE_DETAIL = `
query getCreditNoteDetails($number: String!){
  CreditNoteAccount(input: {am_giftcard_recipient_number : $number}){
    data
    {
      code
      current_balance
      expiration_date
      initial_value
      status
    }
  }
}
`;

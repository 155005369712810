import { gql } from 'graphql-request';

import { MiniCartFragment } from '@/services/graphql/queries/cart/miniCart.gql';
import { CartTriggerFragment } from '@/services/graphql/fragments/header/cartTriggerFragments.gql';
import BundleProductFragment from '@/services/graphql/queries/Product.gql//BundleProductFragment.gql';
import ConfigurableProductFragment from '@/services/graphql/queries/Product.gql/ConfigurableProductFragment.gql';
import { ProductDetailsFragment } from '@/services/graphql/queries/Product.gql/ProductDetailsFragment.gql';
import { ShippingMethod } from '../../fragments/cart/cart.gql';
import { ProductListFragment } from '../../fragments/product/productList.gql';

const CartAddressFragment = `
  city
  company
  country {
    code
    label
  }
  firstname
  lastname
  postcode
  region {
    code
    region_id
  }
  street
  telephone
`;

export const ADD_CONFIGURABLE_MUTATION = gql`
  mutation addConfigurableProductToCart(
    $cartId: String!
    $quantity: Float!
    $sku: String!
    $parentSku: String!
    $entered_options: [EnteredOptionInput]
    $selected_options: [ID]
  ) {
    addConfigurableProductsToCart(
      input: {
        cart_id: $cartId
        cart_items: [
          {
            data: {
              quantity: $quantity
              sku: $sku
              selected_options: $selected_options
              entered_options: $entered_options
            }
            parent_sku: $parentSku
          }
        ]
      }
    ) {
    # addProductsToCart(
    #   cartId: $cartId
    #   cartItems: [
    #     {
    #       quantity: $quantity
    #       sku: $sku
    #       entered_options: $entered_options
    #       selected_options: $selected_options,
    #       parent_sku: $parentSku
    #     }
    #   ]
    # ) {
      cart {
        id
        redeem_gift_card
        # Update the cart trigger when adding an item.
        ...CartTriggerFragment
        # Update the mini cart when adding an item.
        ...MiniCartFragment
        ...ProductListFragment
        shipping_addresses {
          customer_address_id
          ${CartAddressFragment}
          selected_shipping_method {
            ${ShippingMethod}
          }
        }
      }
    }
  }
  ${CartTriggerFragment}
  ${ProductListFragment}
  ${MiniCartFragment}
`;

export const ADD_PRODUCTS_TO_WISHLIST = gql`
  ${ProductDetailsFragment}
  ${BundleProductFragment}
  ${ConfigurableProductFragment}

  mutation addProductsToWishlist(
    $wishlistId: ID!
    $wishlistItems: [WishlistItemInput!]!
  ) {
    addProductsToWishlist(
      wishlistId: $wishlistId
      wishlistItems: $wishlistItems
    ) {
      wishlist {
        id
        items_count
        items_v2 {
          items {
            id
            quantity
            ... on BundleWishlistItem {
              bundle_options {
                values {
                  id
                  label
                  quantity
                }
              }
            }
            product {
              ...ProductDetailsFragment
              ...BundleProductFragment
              ...ConfigurableProductFragment
            }
          }
        }
      }
      user_errors {
        code
        message
      }
    }
  }
`;

export const ADD_BUNDLE_MUTATION = gql`
  mutation addProductsToCart(
    $cartId: String!
    $quantity: Float!
    $sku: String!
    $entered_options: [EnteredOptionInput!]
    $selected_options: [ID]!
  ) {
    addProductsToCart(
      cartId: $cartId
      cartItems: [
        {
          quantity: $quantity
          sku: $sku
          entered_options: $entered_options
          selected_options: $selected_options
        }
      ]
    ) {
      cart {
        id
        redeem_gift_card
        # Update the cart trigger when adding an item.
        ...CartTriggerFragment
        # Update the mini cart when adding an item.
        ...MiniCartFragment
        ...ProductListFragment
        shipping_addresses {
          customer_address_id
          ${CartAddressFragment}
          selected_shipping_method {
            ${ShippingMethod}
          }
        }
      }
      user_errors {
        code
        message
      }
    }
  }
  ${CartTriggerFragment}
  ${MiniCartFragment}
  ${ProductListFragment}
`;

export const ADD_SIMPLE_MUTATION = gql`
  mutation addSimpleProductToCart(
    $cartId: String!
    $quantity: Float!
    $sku: String!
  ) {
    addSimpleProductsToCart(
      input: {
        cart_id: $cartId
        cart_items: [{ data: { quantity: $quantity, sku: $sku } }]
      }
    ) @connection(key: "addSimpleProductsToCart") {
      cart {
        id
        redeem_gift_card
        # Update the cart trigger when adding an item.
        ...CartTriggerFragment
        # Update the mini cart when adding an item.
        ...MiniCartFragment
      }
    }
  }
  ${CartTriggerFragment}
  ${MiniCartFragment}
`;

import cn from 'classnames';

// const colors = is-white|is-light|is-dark|is-black|is-text|
// is-primary|is-link|is-info|is-success|is-warning|is-danger
// small|normal|medium|large
const Buttons = ({ align = '', className = '', size = null, ...props }) => (
  <div
    {...props}
    className={cn('buttons', className, {
      [`is-${size}`]: !!size,
      [`is-${align}`]: !!align,
    })}
  />
);

export default Buttons;

import { Suspense } from 'react';
import { hydrate, render } from 'react-dom';
// import { history } from '@/state/history';
import App from '@/components/App';
import routes from '@/routes';
import { Router } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import loadable, { loadableReady } from '@loadable/component';
import { Provider } from 'react-redux';
import createStore from '@/store/createStore';

// if (process && !process.release) {
//   i18n.use(Backend).use(initReactI18next).use(LanguageDetector);
// }
declare var importScripts;
const ENVIRONMENT_IS_WORKER = typeof importScripts === 'function';

import { fetchIntercept } from '@/services/helpers/fetch-intercept';
import eventEmitter from '@/services/helpers/eventEmitter';
fetchIntercept(ENVIRONMENT_IS_WORKER ? self : window);
window['eventEmitter'] = eventEmitter;

declare var process: any;
var process: any = {
  env: { FORCE_REDUCED_MOTION: false },
};
const store = createStore();

const MainApp = () => {
  const data = window['_INITIAL_DATA_'];
  // useSSR(data.initialI18nStore, data.initialLanguage);
  return (
    <Provider store={store}>
      <BrowserRouter>
        <App
          routes={routes}
          initialData={data}
          appData={window['__APP_DATA__']}
          eventEmitter={eventEmitter}
        />
      </BrowserRouter>
    </Provider>
  );
};
loadableReady(() => {
  const root = document.getElementById('root');
  hydrate(<MainApp />, root);
});
// if (
//   process.env.NODE_ENV === 'development' &&
//   typeof module['hot'] !== 'undefined'
// ) {
//   module['hot'].accept(); /* eslint-disable-line no-undef */
// }
// Webpack 5 Hot Reload
if (process.env.NODE_ENV === 'development' && import.meta['webpackHot']) {
  import.meta['webpackHot'].accept();
}

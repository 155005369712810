import MediaGallery from '@/services/graphql/fragments/product/MediaGallery.gql';
import ProductPrice from '@/services/graphql/fragments/product/ProductPrice.gql';
import { gql } from 'graphql-request';

const ItemProductFragement = `
  thumbnail {
    url
  }
  media_gallery ${MediaGallery}
  description { html }
`;

export default ItemProductFragement;

import { deriveErrorMessage } from '@/services/helpers/deriveErrorMessage';
import { useCallback, useEffect, useMemo } from 'react';
import { getCartId } from '@/services/storage/cart';

import {
  GET_GIFT_CARD_DETAILS,
  APPLY_GIFT_CARD,
  REMOVE_GIFT_CARD,
  ADD_GIFT_CARD_PRODUCTS_TO_CART,
  GET_GIFT_CARD_DETAILS_ACCOUNT,
  GET_CREDIT_NOTE_DETAIL,
} from '@/services/graphql/cart/giftcard/giftcard.gql';
import { useDispatch } from 'react-redux';
import { reload, loading } from '@/store/reducers/cart.reducer';
import useGraphQLRequest from '@/hooks/useGraphQLRequest';
import { setConfig, useSignIn } from '@/hooks/customer/useSignIn';
import { useCart } from '@/hooks/checkout/useCart';
import { useNeevToasts } from '@/components/context/toast';
import { useCartContext } from '@/components/context/CartContext';
import { useCustomer } from '@/hooks/customer/useCustomer';
import { setCurrency } from '@/services/storage/currency';

export const useGiftCard = (props = {}) => {
  const { handleEmptyCart } = useCart({});
  const { addToast } = useNeevToasts();
  const { isSignedIn } = useSignIn();
  const [{ cartItems }]: any = useCartContext();
  const [{ customer }]: any = useCustomer();

  //pass queries/mutations through props, if required
  const {
    getGiftCardCodeDetailsQuery = GET_GIFT_CARD_DETAILS,
    getGiftCardCodeDetailsAccountQuery = GET_GIFT_CARD_DETAILS_ACCOUNT,
    getCreditNoteDetailsQuery = GET_CREDIT_NOTE_DETAIL,
    applyGiftCardToCartMutation = APPLY_GIFT_CARD,
    removeGiftCardFromCartMutation = REMOVE_GIFT_CARD,
    addGiftCardProductsToCartMutation = ADD_GIFT_CARD_PRODUCTS_TO_CART,
  }: any = props || {};

  const dispatch = useDispatch();

  const [
    getGitfCardDetails,
    {
      error: errorGettingGiftCardDetails,
      loading: isGiftCardLoading,
      data: fetchGiftCardDetails,
    },
  ] = useGraphQLRequest({ query: getGiftCardCodeDetailsQuery });

  const [
    applyGiftCardToCart,
    {
      error: errorApplyingGiftCard,
      loading: isApplyingGiftCard,
      data: appliedGiftCardDetails,
    },
  ] = useGraphQLRequest({ query: applyGiftCardToCartMutation });

  const [
    removeGiftCardFromCart,
    {
      error: errorRemovingGiftCard,
      loading: isRemovingGiftCard,
      data: removedGiftCardDetails,
    },
  ] = useGraphQLRequest({ query: removeGiftCardFromCartMutation });

  const [
    addGiftCardProductsToCart,
    {
      error: errorAddGiftCardToCart,
      loading: isAddGiftCardToCart,
      data: addGiftCardToCarDetails,
    },
  ] = useGraphQLRequest({
    query: addGiftCardProductsToCartMutation,
    config: {
      headers: {
        'Content-Currency': 'INR',
      },
    },
  });

  const [
    fetchGiftCardDetailsAccount,
    {
      error: errorFetchingGiftCardDetailsAccount,
      loading: isGiftCardLoadingAccount,
      data: giftCardDataAccount,
    },
  ] = useGraphQLRequest({ query: getGiftCardCodeDetailsAccountQuery });

  const [
    getCreditNoteDetail,
    {
      error: errorFetchingCreditNoteDetails,
      loading: isCreditNoteLoading,
      data: creditNoteData,
    },
  ] = useGraphQLRequest({ query: getCreditNoteDetailsQuery });

  //COMBINE LOADING STATUS
  const isLoading = useMemo(
    () =>
      isGiftCardLoading ||
      isGiftCardLoadingAccount ||
      isCreditNoteLoading ||
      isApplyingGiftCard ||
      isRemovingGiftCard ||
      isAddGiftCardToCart,
    [
      isGiftCardLoadingAccount,
      isGiftCardLoading,
      isCreditNoteLoading,
      isApplyingGiftCard,
      isRemovingGiftCard,
      isAddGiftCardToCart,
    ],
  );

  const giftCardDetails = useMemo(
    () =>
      fetchGiftCardDetails || appliedGiftCardDetails || removedGiftCardDetails,
    [
      fetchGiftCardDetails,
      appliedGiftCardDetails,
      removedGiftCardDetails,
      addGiftCardToCarDetails,
    ],
  );

  //COMBINE ERROR MESSAGES ON CHANGE
  const derivedErrorMessage = useMemo(
    () =>
      deriveErrorMessage([
        errorGettingGiftCardDetails,
        errorApplyingGiftCard,
        errorRemovingGiftCard,
        errorAddGiftCardToCart,
        errorFetchingGiftCardDetailsAccount,
        errorFetchingCreditNoteDetails,
      ]),
    [
      errorGettingGiftCardDetails,
      errorApplyingGiftCard,
      errorRemovingGiftCard,
      errorAddGiftCardToCart,
      errorFetchingGiftCardDetailsAccount,
      errorFetchingCreditNoteDetails,
    ],
  );

  //handlers
  const handleGetGitfCardDetails = useCallback(
    async ({ code }: any) => {
      try {
        if (code) {
          dispatch(loading({}));
          const d = await getGitfCardDetails(
            {
              code,
            },
            setConfig(),
          );

          if (d?.amGiftCardAccount?.status == 'Expired') {
            addToast(`Code ${code} is expired.`, {
              appearance: 'error',
              autoDismiss: true,
            });
          }

          dispatch(loading({ loading: false }));
        }
      } catch (e) {
        dispatch(loading({ loading: false }));
        return;
      }
    },
    [getGitfCardDetails],
  );

  const handleApplyGiftCardToCart = useCallback(
    async ({ code }) => {
      try {
        const cartId = getCartId();
        dispatch(loading({}));

        const d = await applyGiftCardToCart(
          {
            cart_id: cartId,
            am_gift_card_code: code,
            cartPrices: true,
          },
          setConfig(),
        );

        //update cart related states
        if (d?.applyAmGiftCardToCart?.cart) {
          const appliedCoupons =
            d?.applyAmGiftCardToCart?.cart?.applied_am_gift_cards || [];

          //show latest added applied coupons in toast
          if (Array.isArray(appliedCoupons) && appliedCoupons?.length) {
            addToast(appliedCoupons.reverse()[0].msg, {
              appearance: 'success',
              autoDismiss: true,
            });
          }
          dispatch(reload({ ...d.applyAmGiftCardToCart.cart }));
        } else {
          dispatch(loading({ loading: false }));
        }
        return d;
      } catch (e) {
        dispatch(loading({ loading: false }));
        return;
      }
    },
    [applyGiftCardToCart],
  );

  const handleRemoveGiftCard = useCallback(
    async ({ code }) => {
      try {
        dispatch(loading({}));
        let cartId = getCartId();
        if (cartId) {
          const d = await removeGiftCardFromCart(
            {
              cart_id: cartId,
              am_gift_card_code: code,
              cartPrices: true,
            },
            setConfig(),
          );

          //update cart related states
          if (d?.removeAmGiftCardFromCart?.cart) {
            dispatch(reload({ ...d.removeAmGiftCardFromCart.cart }));
          } else {
            dispatch(loading({ loading: false }));
          }
        }
      } catch (e) {
        dispatch(loading({ loading: false }));
        return;
      }
    },
    [removeGiftCardFromCart],
  );

  const handleAddGiftCardToCart = useCallback(
    async (payload) => {
      try {
        dispatch(loading({}));
        await handleEmptyCart();
        let cartId = getCartId();
        if (cartId) {
          const d = await addGiftCardProductsToCart(
            {
              cart_id: cartId,
              cartPrices: true,
              ...payload,
            },
            setConfig(),
          );
          //update cart related states
          if (d?.addAmGiftCardProductsToCart?.cart) {
            setCurrency('INR');
            dispatch(reload({ ...d.addAmGiftCardProductsToCart.cart }));
            addToast('Gift card added successfully', {
              appearance: 'success',
              autoDismiss: true,
            });
          } else {
            dispatch(loading({ loading: false }));
            addToast(
              d?.response?.errors[0]?.message ||
                'Something went. Please try again.',
              {
                appearance: 'error',
                autoDismiss: true,
              },
            );
          }
        } else {
          dispatch(loading({ loading: false }));
        }
      } catch (e) {
        dispatch(loading({ loading: false }));
        return;
      }
    },
    [removeGiftCardFromCart],
  );

  const handleGetGiftCardDetailAccount = useCallback(
    async ({ number }: any) => {
      try {
        if (number) {
          dispatch(loading({}));
          await fetchGiftCardDetailsAccount(
            {
              number,
            },
            setConfig(),
          );

          dispatch(loading({ loading: false }));
        }
      } catch (e) {
        dispatch(loading({ loading: false }));
        return;
      }
    },
    [fetchGiftCardDetailsAccount],
  );

  const handleGetCreditNoteDetail = useCallback(
    async ({ number }: any) => {
      try {
        if (number) {
          dispatch(loading({}));
          await getCreditNoteDetail(
            {
              number,
            },
            setConfig(),
          );

          dispatch(loading({ loading: false }));
        }
      } catch (e) {
        dispatch(loading({ loading: false }));
        return;
      }
    },
    [getCreditNoteDetail],
  );

  //check if cart item has gift card items
  const handleCheckIfCartHasGiftCard = useCallback(
    ({ cartItems = [], all = true }) => {
      return (
        !!cartItems?.length &&
        cartItems[all ? 'every' : 'find'](
          (e: any) => e?.product?.__typename === 'AmGiftCardProduct',
        )
      );
    },
    [cartItems],
  );

  //exclude neev gift card address from custome to view
  const handleExcludeNeevGiftCardAddress = useCallback(
    ({ address = [], strict = false }) => {
      return (address?.length ? address : customer.addresses)?.filter(
        (e: any) => {
          return (
            String(e.street?.[0] || '')
              .toUpperCase()
              .indexOf('NEEV_GIFT_CARD') < 0
          );
        },
      );
    },
    [customer],
  );

  //this flag is used to disable shipping and billing address from checkout, if cart has only gift cards
  const isCartHasOnlyGiftCards = useMemo(() => {
    return handleCheckIfCartHasGiftCard({ cartItems });
  }, [cartItems]);

  const isCartHasGiftCards = useMemo(() => {
    return handleCheckIfCartHasGiftCard({ cartItems, all: false });
  }, [cartItems]);

  useEffect(() => {
    if (derivedErrorMessage) {
      addToast(derivedErrorMessage, {
        statusCode: 500,
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [derivedErrorMessage]);

  return {
    isLoading,
    isCartHasOnlyGiftCards,
    isCartHasGiftCards,
    errorMessage: derivedErrorMessage,
    giftCardDetails,
    giftCardDataAccount,
    creditNoteData,
    handleRemoveGiftCard,
    handleApplyGiftCardToCart,
    handleGetGitfCardDetails,
    handleGetGiftCardDetailAccount,
    handleAddGiftCardToCart,
    handleGetCreditNoteDetail,
    handleCheckIfCartHasGiftCard,
    handleExcludeNeevGiftCardAddress,
  };
};

import useWindowSize from '@/hooks/common/useWindowSize';
import { getCookieConsentIsDeclined } from '@/services/storage/cookie-consent';
import { createContext, useMemo, useContext, useState } from 'react';
import { connect } from 'react-redux';
// import variables from './app.scss';
// var regexr = /(.*):(.*);/gim;
// var varString = variables.toString();
// var m = varString.matchAll(regexr);
// const breakpoints = {};
// var match = m.next();
// do {
//   if (match && match.value) {
//     breakpoints[match.value[1].trim()] = match.value[2].trim();
//     match = m.next();
//   }
// } while (match);

interface IAppState {}
interface IAppApi {}
type IAppContext = any[];
const initialAppState: IAppState = {};
const initialAppApi: IAppApi = {};

var e = [
  {
    preventDefault: () => {
      console.log('Hello');
    },
  },
];

const AppContext = createContext<IAppContext>([{}, {}]);
const preventDefault = (e) => {
  e && e[0] && e[0].preventDefault && e[0].preventDefault();
};
const AppContextProvider = (props) => {
  const { children, eventEmitter, initialData } = props;
  const [deviceType, setDeviceType] = useState(props.deviceType);

  useWindowSize(({ height, width }) => {
    // { vtablet: '769px', vdesktop: '1024px' }
    let newDeviceType = 'mobile';
    if (width > 1024) {
      newDeviceType = 'desktop';
    } else if (width > 769) {
      newDeviceType = 'tablet';
    }
    if (deviceType != newDeviceType) {
      // Refresh complete all for device type change
      setDeviceType(newDeviceType);
    }
  }, deviceType);

  const [modal, setModal] = useState<any>('');
  const onModal = (...args) => {
    const [modal] = args;
    setModal(modal);
  };

  eventEmitter && eventEmitter.on('onModal', onModal);
  class ModalEvents {
    closeModals = () => {
      eventEmitter && eventEmitter.emit('onModal', null);
    };
    openLoginModal = (...e) => {
      preventDefault(e);
      if (getCookieConsentIsDeclined()) {
        this.openCookiePolicyModal();
        return;
      }
      eventEmitter.emit('onModal', 'common.login', e);
      return false;
    };
    openRegisterModal = (...e) => {
      preventDefault(e);
      if (getCookieConsentIsDeclined()) {
        this.openCookiePolicyModal();
        return;
      }

      eventEmitter.emit('onModal', 'common.register', e[0]);
      return false;
    };
    openForgotPasswordModal = (...e) => {
      preventDefault(e);
      if (getCookieConsentIsDeclined()) {
        this.openCookiePolicyModal();
        return;
      }

      eventEmitter.emit('onModal', 'common.forgotPassword', e?.slice(1));
      return false;
    };
    openCookiePolicyModal = (...e) => {
      preventDefault(e);
      eventEmitter.emit('onModal', 'common.cookiePolicy', e?.slice(1));
      return false;
    };
    openModal = (model, ...args) => {
      eventEmitter.emit('onModal', ...args);
    };
  }

  const appApi = useMemo(() => new ModalEvents(), []);

  const appState = {
    deviceType,
    eventEmitter,
    isMobile: deviceType == 'mobile',
    isTablet: deviceType == 'tablet',
    isDesktop: deviceType == 'desktop',
    menu: props.menu,
    storeConfig: props.storeConfig,
    currencyList: props.currencyList,
    isAppLoading: props.isAppLoading,
    handleLoader: props.handleLoader,
    countriesRegions: props.countriesRegions,
    initialData,
    modal,
  };

  const contextValue: IAppContext = useMemo(
    () => [appState, appApi],
    [appState, appApi, modal],
  );

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};

const mapStateToProps = ({ app }) => ({ appState: app });

const mapDispatchToProps = (dispatch) => ({
  // actions: bindActionCreators(actions, dispatch),
  // asyncActions: bindActionCreators(asyncActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppContextProvider);

export const useAppContext = () => useContext(AppContext);

import { createSlice } from '@reduxjs/toolkit';

// import expireReducer from 'redux-persist-expire';

const initialState = {
  token: '',
  loaded: false,
  loading: false,
  isSignedIn: false,
  customer: {},
};

export const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action) => {
      state = {
        ...state,
        ...action.payload,
        loaded: true,
        loading: false,
        isSignedIn: true,
      };

      return state;
    },
    loading: (state, action) => {
      state = {
        ...state,
        loaded: false,
        loading: true,
        ...action.payload,
      };

      return state;
    },
    loginWithToken: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state = {
        ...state,
        ...action.payload,
        loaded: true,
        isSignedIn: true,
        loading: false,
      };

      return state;
    },

    logout: (state) => {
      state = {
        ...initialState,
        loaded: true,
        isSignedIn: false,
      };
      return state;
    },
    reload: (state, action) => {
      state = {
        ...state,
        ...action.payload,
        loaded: true,
        loading: false,
      };

      return state;
    },
    reloadCustomer: (state, action) => {
      state.customer = {
        ...state.customer,
        ...action.payload,
        loaded: true,
        loading: false,
      };

      return state;
    },
  },
});

//Export Actions
export const {
  loginWithToken,
  logout,
  login,
  reload,
  reloadCustomer,
  loading,
} = auth.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.auth.token)`
export const selectAuth = (state: any) => ({ ...state.auth });
export const selectCustomer = (state: any) => ({ ...state.auth.customer });

export default auth.reducer;

// export const expireReducerAuth = () =>
//   expireReducer('auth', {
//     //expire in one day
//     expireSeconds: 86400,
//     autoExpire: true,
//     expiredState: initialState,
//   });

import { useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import SvgIcon from '@/components/atoms/icon/SvgIcon';
import { useAppContext } from '@/components/context/AppContext';
import Columns from '@/components/atoms/Columns';
import { SelectCurrency } from '../HeaderTop/components';
import { DesktopMenu } from './DesktopMenu';
import { MobileLoginButton } from './MobileLoginButton';
import { SliderMenu } from './SlideMenu';

import styles from './HeaderMenu.module.scss';
import './menu.scss';
import { useSignIn } from '@/hooks/customer/useSignIn';
import { getCookieConsentIsDeclined } from '@/services/storage/cookie-consent';
import useWindowSize from '@/hooks/common/useWindowSize';

const HeaderMenu = ({ showMenu, handleMenuVisibility, currency = {} }: any) => {
  const ref: any = useRef();
  const [
    { menu, isDesktop, isMobile },
    { openLoginModal, openCookiePolicyModal },
  ] = useAppContext();
  const [active, setActive] = useState(-1);
  const categories = menu || [];
  const { isSignedIn } = useSignIn();
  const signedIn = isSignedIn();
  useWindowSize(({ height }) => {
    if (isMobile) {
      console.log('ref', ref.current, height);
      if (ref.current) {
        ref.current.style.height = `${height}px`;
      }
    }
  }, isMobile);

  const children = useMemo(() => {
    return (
      <DesktopMenu
        {...{
          active,
          categories,
          handleMenuVisibility,
        }}
      />
    );
  }, [active || menu]);

  return (
    <aside
      ref={ref}
      className={cn(
        styles.navmenu,
        'menu is-flex is-flex-direction-column',
        {
          'pb-0': isMobile,
        },
        showMenu && styles.toggle,
      )}
    >
      <span className={cn(styles.close, 'is-hidden-desktop')}>
        <SvgIcon
          onClick={() => handleMenuVisibility(false)}
          icon={'close'}
          size={'30'}
        />
      </span>
      <div className={cn(styles.profile, 'is-hidden-desktop')}>
        <SelectCurrency
          parentClassName={styles['select-gap']}
          options={currency.list}
          defaultOption={currency.default}
        />
        {!isDesktop && (
          <MobileLoginButton
            onClose={() => {
              handleMenuVisibility(false);
            }}
          />
        )}
      </div>
      <nav>
        {!isMobile && <>{children}</>}
        <SliderMenu
          {...{
            setActive,
            handleMenuVisibility,
            active,
            categories,
          }}
        />
      </nav>
      <Columns
        multiline
        breakpoint={'mobile'}
        className={cn('mt-auto mx-0 px-4 pb-2 bg-profile pt-2', {
          'mb-0': isMobile,
        })}
      >
        <Columns.Column
          className={'is-6-mobile has-text-right-mobile'}
          size={'12-desktop'}
        >
          <Link
            onClick={() => {
              handleMenuVisibility(false);
            }}
            to='/gift-card#send'
            className={'is-size-6 py-3 ternary-color mt-2'}
            title={'E-Gift Cards'}
          >
            E-Gift Cards
          </Link>
        </Columns.Column>
        <Columns.Column className={'is-6-mobile'} size={'12-desktop'}>
          <Link
            onClick={(e) => {
              handleMenuVisibility(false);

              if (getCookieConsentIsDeclined()) {
                e.preventDefault();
                openCookiePolicyModal();
              } else {
                if (!signedIn) {
                  e.preventDefault();
                  openLoginModal(true);
                }
              }
            }}
            to='/profile#myorders'
            className={'is-size-6 py-3 ternary-color'}
            title={'Track Order'}
          >
            Track Order
          </Link>
        </Columns.Column>
      </Columns>
      {/* Commented as not required any more by neev team */}
      {/* <Columns className='mx-0 px-4 bg-profile pb-5'>
        <Columns.Column className='mt-2 has-text-centered-mobile'>
          <a
            className={cn('mr-4 is-inline-block', styles.icon)}
            title='Neev Instagram'
            target={'_blank'}
            href={
              'https://www.instagram.com/neev_by_vibhutikabra/?igshid=18xpp1tu1mg32'
            }
          >
            <Icon>
              <FontAwesomeIcon
                className='primary-color'
                size={'2x'}
                icon={faInstagram}
              />
            </Icon>
          </a>
          <a
            className={cn(styles.icon, 'mx-2 is-inline-block')}
            title='Neev Facebook'
            target={'_blank'}
            href={'https://www.facebook.com/Neevbyvibhutikabra'}
          >
            <SvgIcon icon={'fb-primary'} size={'32'} />
          </a>
        </Columns.Column>
      </Columns> */}
    </aside>
  );
};

export default HeaderMenu;

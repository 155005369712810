import { gql } from 'graphql-request';
import { ProductDetailAttributes as CoreAttributes } from 'src/core/services/graphql/queries/Product.gql/ProductDetailAttributes.gql';

export const ProductDetailAttributes = `
  ${CoreAttributes}
  
  price_per_metre
  length
  width
  qty_range
  dispatch_time
  fabric
  work
  care
  color
  description_note

  top_care
  top_work
  top_fabric
  top_length
  top_width

  bottom_care
  bottom_work
  bottom_fabric
  bottom_length
  bottom_width

  dupatta_care
  dupatta_work
  dupatta_fabric
  dupatta_length
  dupatta_width

  is_in_stock

  core_meta: dynamicAttributes(fields: ["fabric", "work", "dispatch_time"])
  top_meta: dynamicAttributes(fields: ["top_fabric", "top_work"])
  dupatta_meta: dynamicAttributes(fields: ["dupatta_fabric", "dupatta_work"])
  bottom_meta: dynamicAttributes(fields: ["bottom_fabric", "bottom_work"])
`;

import * as React from 'react';
import cn from 'classnames';
// Different type of Icons recevied from various components
interface IIcon {
  icon:
  | 'account'
  | 'add-bookmark-added'
  | 'email'
  | 'add-bookmark'
  | 'add'
  | 'add-bold'
  | 'facebook'
  | 'twitter'
  | 'linkedin'
  | 'instagram'
  | 'youtube'
  | 'arrow-down-circle'
  | 'arrow-down'
  | 'arrow-dropdown'
  | 'arrow-dropup'
  | 'arrow-left'
  | 'arrow-right'
  | 'arrow-up'
  | 'search'
  | 'view-grid'
  | 'view-list'
  | 'star-filled'
  | 'star-empty'
  | 'star-half'
  | 'call'
  | 'message'
  | 'worldwide-shipping-truck'
  | 'shoppingcart'
  | 'worldwide-shipping'
  | 'build'
  | 'black'
  | 'check-circle-outline'
  | 'check-circle'
  | 'check'
  | 'checkbox-checked'
  | 'checkbox-intermediate'
  | 'checkbox-unchecked'
  | 'chevron-down'
  | 'chevron-left'
  | 'chevron-right'
  | 'chevron-up'
  | 'close-circle'
  | 'close'
  | 'close-opaque'
  | 'delete'
  | 'download'
  | 'download'
  | 'edit'
  | 'error-filled'
  | 'error-filled'
  | 'favourite-outline'
  | 'favourite'
  | 'filter'
  | 'home-filled'
  | 'home'
  | 'location-outline'
  | 'location'
  | 'menu'
  | 'mobile'
  | 'more-horizontal'
  | 'more-vertical'
  | 'neev'
  | 'offer-copy'
  | 'offer'
  | 'person'
  | 'play-filled'
  | 'play-outline'
  | 'radio-checked'
  | 'radio-unchecked'
  | 'remove'
  | 'save'
  | 'search-copy'
  | 'setting'
  | 'shape-cutout'
  | 'shape-landscape'
  | 'shape-potrait'
  | 'shape-round'
  | 'shape-square'
  | 'shape-triangle'
  | 'sheild'
  | 'shoppingcart-filled'
  | 'show-password'
  | 'sort'
  | 'minus-bold'
  | 'tag'
  | 'logout'
  | 'time'
  | 'unfold-less'
  | 'unfold-more'
  | 'view-grid'
  | 'visibility-off'
  | 'visibility'
  | 'zoom-in'
  | 'zoom-out'
  | 'ic-location'
  | 'calendar'
  | 'profile-design'
  | any;
  id?: string;
  size?: string;
  onClick?: React.MouseEventHandler;
  onMouseEnter?: React.MouseEventHandler;
  onMouseLeave?: React.MouseEventHandler;
  className?: string;
  style?: any;
  minWidth?: any;
  minHeight?: any;

}

//Component used to generate graphics-Icon for our web app
const SvgIcon: React.SFC<IIcon> = ({
  icon,
  onClick,
  onMouseEnter,
  onMouseLeave,
  size = '16',
  style = {},
  className = null,
  id,
  ...props
}) => {
  const classnames = cn(
    'ii-icon',
    `i-${icon}`,
    // `i-${style}`,
    'pointer',
    className
  );
  return (
    <svg
      {...props}
      id={id}
      className={classnames}
      height={size}
      width={size}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      style={{ minHeight: +size, minWidth: +size, ...style }}
    >
      <use xlinkHref={`#i-${icon}`}></use>
    </svg>
  );
};

export default SvgIcon;

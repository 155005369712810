import CoreMediaGallery from 'src/core/services/graphql/fragments/product/MediaGallery.gql';

const MediaGallery = `{
  disabled
  label
  position
  url
  landscape
  ... on ProductVideo {
    video_content {
      media_type
      video_provider
      video_url
      video_title
      video_description
      video_metadata
    }
  }
}`;

export default MediaGallery;

import FixedProductTax from './FixedProductTax.gql';
import Money from './Money.gql';
import ProductDiscount from './ProductDiscount.gql';

const ProductPrice = () => `{
  discount ${ProductDiscount}
  final_price ${Money}
  fixed_product_taxes ${FixedProductTax}
  regular_price ${Money}
}`;
export default ProductPrice;

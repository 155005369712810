import { FC, HTMLProps, Ref } from 'react';

interface RadioProps extends HTMLProps<HTMLInputElement> {
  name: string;
  style?: any;
  disabled?: boolean;
  value?: string;
  checked?: boolean;
  defaultChecked?: boolean;
  domRef?: Ref<any>;
  getRef?: Ref<any>;
  id: string;
  labelprops?: any;
}

export type IRadio = FC<RadioProps>;
const Radio: IRadio = ({
  className,
  style,
  disabled,
  value,
  children,
  checked,
  name,
  domRef,
  id,
  getRef,
  labelprops,
  ...props
}) => {
  return (
    <>
      <input
        {...props}
        name={name}
        type='radio'
        value={value}
        className='is-radio'
        ref={getRef}
        disabled={disabled}
        id={id}
        checked={checked}
      />
      <label
        ref={domRef}
        className={className}
        style={style}
        htmlFor={id}
        {...labelprops}
      >
        {children}
      </label>
    </>
  );
};

export default Radio;

import { deriveErrorMessage } from '@/services/helpers/deriveErrorMessage';
import { useCallback, useMemo, useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { getCartId, removeCartId, setCartId } from '@/services/storage/cart';

import useGraphQLRequest from '../useGraphQLRequest';

import {
  GET_CUSTOMER_CART,
  ADD_TO_CART,
  REMOVE_CART_ITEM,
  UPDATE_CART_ITEMS,
  CREATE_EMPTY_CART,
} from '@/services/graphql/fragments/cart/cart.gql';
import { CartContext } from '@/components/context/CartContext';
import { setConfig, useSignIn } from '../customer/useSignIn';
import { getToken } from '@/services/storage/user';
import { useDispatch, useSelector } from 'react-redux';
import { reload, loading } from '@/store/reducers/cart.reducer';
import { useNeevToasts } from '@/components/context/toast';
import useQueryString from '../useQueryString';

export const useCart = (props: any) => {
  //pass queries/mutations through props, if required
  const {
    getCustomerCartQuery = GET_CUSTOMER_CART,
    addToCartMutatiom = ADD_TO_CART,
    removeCartItemMutatiom = REMOVE_CART_ITEM,
    updateCartItemMutatiom = UPDATE_CART_ITEMS,
    createEmptyCartMutation = CREATE_EMPTY_CART,
  } = props || {};

  const history = useHistory();
  const dispatch = useDispatch();
  const { addToast } = useNeevToasts();
  const cartItemsList = useSelector((state: any) => state?.cart?.items);
  const {
    location: { pathname },
    replace,
  } = history;

  const [
    {
      params: { decodedValue: params },
    },
  ]: any = useQueryString();

  //CREATE A EMPTY CART
  const [
    createEmptyCart,
    { error: errorCreatingEmptyCart, loading: isCreatingEmptyCart },
  ] = useGraphQLRequest({
    query: createEmptyCartMutation,
  });

  const handleEmptyCart = useCallback(async () => {
    try {
      const cartId = getCartId();

      if (!cartId) {
        const d = await createEmptyCart({}, setConfig());
        d && d.createEmptyCart && setCartId(d.createEmptyCart);
        return d.createEmptyCart;
      }
    } catch (e) {
      console.log('Error', e);
      return;
    }
  }, [createEmptyCart]);

  //Fecth Customer Cart Details
  const [
    getCustomerCart,
    {
      error: errorGettingCartDetails,
      loading: isCartLoading,
      data: cartDetails,
    },
  ] = useGraphQLRequest({ query: getCustomerCartQuery });

  const handleGetCustomerCart = useCallback(
    async ({
      cartId = getCartId(),
      cartItems = true,
      cartPrices = true,
      paymentMethod = false,
      billing = false,
      shipping = false,
      shippingMethod = false,
      availableShippingMethods = false,
      availablePaymentMethods = true,
    }: any) => {
      try {
        if (params?.clear_cart_id) {
          removeCartId();
          replace(pathname);
          return;
        }
        if (cartId) {
          // const isCartHasGiftCards =
          //   Array.isArray(cartItemsList) &&
          //   cartItemsList?.find(
          //     (e) => e?.product?.__typename === 'AmGiftCardProduct',
          //   );

          dispatch(loading({}));
          const { cart } =
            (await getCustomerCart(
              {
                cart_id: cartId,
                cartItems,
                cartPrices,
                paymentMethod,
                billing,
                shipping,
                shippingMethod,
                availableShippingMethods,
                availablePaymentMethods,
              },
              setConfig(),
            )) || {};

          //update cart related states
          if (cart) {
            //set in store
            dispatch(reload({ ...cart }));
          } else {
            dispatch(loading({ loading: false }));
          }
        }
      } catch (e) {
        console.log('Cart Fetching Error', e);
        dispatch(loading({ loading: false }));
        return;
      }
    },
    [getCustomerCart, params, cartItemsList],
  );

  //Add item to cart
  const [
    addToCart,
    {
      error: errorAddingItemToCart,
      loading: isAddingItemToCart,
      data: cartDetailsAfterAddingItem,
    },
  ] = useGraphQLRequest({ query: addToCartMutatiom });

  const handleAddToCart = useCallback(
    async (payload) => {
      try {
        let cartId = getCartId();
        if (!cartId) {
          cartId = await handleEmptyCart();
        }

        dispatch(loading({}));
        const d = await addToCart(
          {
            cart_id: cartId,
            cartItems: true,
            ...payload,
          },
          setConfig(),
        );

        //update cart related states
        if (d?.addProductsToCart?.cart) {
          dispatch(reload({ ...d.addProductsToCart.cart }));
        }

        //update states
      } catch (e) {
        dispatch(loading({ loading: false }));
        console.log('AddToCart', e);
        return;
      }
    },
    [addToCart],
  );

  //Remove item to cart
  const [
    removeCartItem,
    {
      error: errorRemovingCartItem,
      loading: isRemovingCartItem,
      data: cartDetailsAfterRemovingCartItem,
    },
  ] = useGraphQLRequest({ query: removeCartItemMutatiom });

  const handleRemoveCartItem = useCallback(
    async ({ cart_item_id }) => {
      try {
        dispatch(loading({}));
        let cartId = getCartId();
        if (cartId) {
          const d = await removeCartItem(
            {
              cart_id: cartId,
              cart_item_id,
              cartPrices: true,
              shipping: true,
              shippingMethod: true,
              availableShippingMethods: true,
            },
            setConfig(),
          );

          //update cart related states
          if (d?.removeItemFromCart?.cart) {
            dispatch(reload({ ...d.removeItemFromCart.cart }));
          } else {
            dispatch(reload({}));
          }
        }
        //update states
      } catch (e) {
        dispatch(loading({ loading: false }));
        console.log('Remove item from cart', e);
        return;
      }
    },
    [removeCartItem],
  );

  const [
    updateCartItem,
    {
      error: errorUpdatingCartItem,
      loading: isUpdatingCartItem,
      data: cartDetailsAfterUpdatingCartItem,
    },
  ] = useGraphQLRequest({ query: updateCartItemMutatiom });

  const handleUpdateCartItem = useCallback(
    async (payload = [], enableLoading = true) => {
      try {
        let cartId = getCartId();

        if (cartId) {
          if (enableLoading) {
            dispatch(loading({}));
          } else {
            const cartItemId = payload?.[0]?.cart_item_uid;
            dispatch(reload({ updatingCartItem: cartItemId }));
          }

          const d = await updateCartItem(
            {
              cart_id: cartId,
              cart_items: payload,
              cartPrices: true,
              shipping: true,
              shippingMethod: true,
              availableShippingMethods: true,
              paymentMethod: true,
              availablePaymentMethods: true,
            },
            setConfig(),
          );

          //update cart related states
          if (d?.updateCartItems?.cart) {
            dispatch(
              reload({ ...d.updateCartItems.cart, updatingCartItem: null }),
            );
            return d;
          } else {
            dispatch(reload({ updatingCartItem: null }));
          }
        }
      } catch (e) {
        dispatch(reload({ updatingCartItem: null }));

        console.log('AddToCart', e);
        return;
      }
    },
    [updateCartItem],
  );

  //TODO, need to check if required
  const handleClearCart = useCallback(
    async (payload = []) => {
      try {
        let cartId = getCartId();
        //update states
      } catch (e) {
        console.log('AddToCart', e);
        return;
      }
    },
    [addToCart],
  );

  //COMBINE ERROR MESSAGES ON CHANGE
  const derivedErrorMessage = useMemo(
    () =>
      deriveErrorMessage([
        errorGettingCartDetails,
        errorAddingItemToCart,
        errorRemovingCartItem,
        errorUpdatingCartItem,
        errorCreatingEmptyCart,
      ]),
    [
      errorGettingCartDetails,
      errorAddingItemToCart,
      errorRemovingCartItem,
      errorUpdatingCartItem,
      errorCreatingEmptyCart,
    ],
  );

  useEffect(() => {
    if (derivedErrorMessage) {
      const couldNotFindCart =
        String(derivedErrorMessage).indexOf('Could not find a cart with ID') >
        -1;

      if (couldNotFindCart) removeCartId();

      if (!couldNotFindCart) {
        addToast(derivedErrorMessage, {
          // statusCode: 500,
          appearance: 'error',
          autoDismiss: true,
        });
      }
    }
  }, [derivedErrorMessage]);

  //COMBINE LOADING STATUS
  const isLoading = useMemo(
    () =>
      isCartLoading ||
      isAddingItemToCart ||
      isCreatingEmptyCart ||
      isUpdatingCartItem ||
      isRemovingCartItem,
    [
      isCartLoading,
      isAddingItemToCart,
      isUpdatingCartItem,
      isRemovingCartItem,
      isCreatingEmptyCart,
    ],
  );

  //redirections
  const toSignIn = ({ cb, override = false }) => {
    cb && cb();
    !override && history.push('/login');
  };

  return {
    isCartLoading: isLoading,
    errorMessage: derivedErrorMessage,
    cartDetails: {
      ...cartDetails,
      ...cartDetailsAfterAddingItem,
      ...cartDetailsAfterRemovingCartItem,
      ...cartDetailsAfterUpdatingCartItem,
    },
    isLoggedIn: false,
    toSignIn,
    handleGetCustomerCart,
    handleAddToCart,
    handleRemoveCartItem,
    handleUpdateCartItem,
    handleClearCart,
    handleEmptyCart,
  };
};

import PersistanceStorage from '.';
import { getToken } from './user';

const storage = new PersistanceStorage();

export const getBillingAsShipping = () => {
  // if (getToken()) {
    // return '';
  // }
  return storage.getItem('billing');
};

export const setBillingAsShipping = (flag = true) => {
  // if (!getToken()) {
    storage.setItem('billing', flag);
  // }
};

export const removeBillingAsShipping = () => {
  storage.removeItem('billing');
};

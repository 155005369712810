import Columns from '@/components/atoms/Columns';
import styles from './HeaderTop.module.scss';
import cn from 'classnames';
import { Link, matchPath, useHistory } from 'react-router-dom';
import SvgIcon from '@/components/atoms/icon/SvgIcon';
import Select from '@/components/atoms/Form/components/Select';
import { getCurrency, setCurrency } from '@/services/storage/currency';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppContext } from '@/components/context/AppContext';
import { useSignIn } from '@/hooks/customer/useSignIn';
import { selectCustomer } from '@/store/reducers/auth.reducer';
import SearchBar from '../SearchBar';
import { useMemo } from 'react';
import { useHomePageContext } from '@/hooks/useHome';
import { useGiftCard } from '@/hooks/cart/useGiftCard';
import { getCookieConsentIsDeclined } from '@/services/storage/cookie-consent';

export const MobileCreateAccountContainer = () => {
  const [{ isDesktop }, { openLoginModal }] = useAppContext();
  if (!isDesktop) return null;

  return (
    <Columns
      vCentered
      centered
      className={cn(
        'is-hidden-desktop has-text-white has-text-centered is-size-7 m-0',
        styles['login-hint'],
      )}
    >
      <Columns.Column onClick={openLoginModal}>
        Create/Login to your account to avail NEEV CIRCLE benefits.
      </Columns.Column>
    </Columns>
  );
};

export const ShopLink = ({ onClick, className }) => {
  return (
    <Columns.Column className='is-uppercase'>
      <Link onClick={onClick} className={className} to='/' title='Shop'>
        shop
      </Link>
    </Columns.Column>
  );
};

export const BannerMessage = ({ hide }) => {
  const [{ isMobile, initialData }] = useAppContext();
  // const [{ Banner }] = useHomePageContext();
  const { Banner } = initialData || {};
  if (!Banner) return null;

  return (
    <Columns.Column
      mobile={{
        // hide: { value: true },
        textAlignment: { value: 'centered' },
      }}
      className={cn('has-text-centered', styles.banner, {
        [styles.fixed]: isMobile && !hide,
        'f-14': !isMobile,
        'is-size-7': isMobile,
      })}
      desktop={{
        size: '8',
      }}
      tablet={{
        size: '6',
      }}
    >
      {initialData?.Banner}
      {/* Free international shipping on orders over Rs. 25,000 &bull; Watch our
      Live session for brand new collection ! */}
    </Columns.Column>
  );
};

export const Logo = ({ hide }) => {
  const [{ storeConfig = {} }] = useAppContext();
  return (
    <Columns.Column
      className={cn(
        'has-text-centered primary-color is-size-3-desktop is-size-5-mobile',
        styles.heading,
        styles.show,
        {
          ['is-hidden']: !hide,
        },
      )}
      size='5'
    >
      <a href='/' title='Neev'>
        <img src={storeConfig?.logoUrl} className={styles.logo} />
        {/* <img src='/logo/neev.svg' className={styles.logo} /> */}
      </a>
    </Columns.Column>
  );
};

export const AnimatedMobileLogo = ({ show, hide }) => {
  if (!hide) return null;

  return (
    <Columns.Column
      className={cn(
        'has-text-centered is-hidden-desktop is-size-5-mobile',
        styles.heading,
        show && styles.show,
      )}
      size='5'
    >
      NEEV
    </Columns.Column>
  );
};

export const Search = ({ toggle }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <SearchBar
        headerSwitched={toggle}
        toggle={open}
        close={() => setOpen(false)}
      />
      <span className='px-3 is-flex is-align-items-center'>
        <SvgIcon
          onClick={() => setOpen(!open)}
          icon={toggle ? 'search-grey' : 'search-neev'}
          size='24'
        />
      </span>
    </>
  );
};

export const SelectCurrency = ({
  className = '',
  parentClassName = '',
  options = [],
  defaultOption = '',
}) => {
  const [value, setValue] = useState(defaultOption);
  const { isCartHasGiftCards } = useGiftCard();

  useEffect(() => {
    defaultOption = getCurrency() || defaultOption;
    setValue(defaultOption);
  }, [defaultOption]);

  if (isCartHasGiftCards) return null;

  return (
    <Select
      onChange={(e: any) => {
        const v = e.currentTarget.value;
        setCurrency(v);
        setValue(v);
        window.location.reload();
      }}
      parentClassName={cn(parentClassName)}
      value={value}
      className={cn(styles.currency, className, 'mx-2 ml-3 is-vcentered')}
    >
      {options.map((e: any, i) => (
        <option value={e.currency_to} key={i}>
          {e.currency_to}
        </option>
      ))}
    </Select>
  );
};

export const CartBag = ({ count, onClick, toggle }) => {
  const { location } = useHistory();
  const disableCartBag = useMemo(() => {
    return !!matchPath(location.pathname, {
      exact: true,
      path: '/checkout/details',
    });
  }, [location]);

  if (disableCartBag) return null;

  return (
    <div onClick={onClick} className={cn(styles.bag, 'pl-3 pointer')}>
      <span className={cn(styles.bagCount, 'px-1 has-text-white')}>
        {count}
      </span>
      <SvgIcon icon={toggle ? 'bag-grey' : 'bag'} size='22' />
    </div>
  );
};

export const LoginButton = () => {
  const { isSignedIn, handleCustomer } = useSignIn();
  const signedIn = isSignedIn();
  const customer = useSelector(selectCustomer);
  const [, { openLoginModal, openCookiePolicyModal }] = useAppContext();

  useEffect(() => {
    if (signedIn) handleCustomer({ withCart: true });
  }, [signedIn, customer?.created_at]);

  if (signedIn && customer?.created_at) {
    return (
      <Link
        style={{ whiteSpace: 'nowrap' }}
        className='px-3 has-text-weight-normal is-capitalized f-16'
        to='/profile'
        title='Profile'
      >
        Hi, {customer?.firstname || 'User'}
      </Link>
    );
  }

  return (
    <span
      style={{ whiteSpace: 'nowrap' }}
      className='px-3 f-16 pointer'
      title='Profile'
      onClick={() => {
        if (getCookieConsentIsDeclined()) {
          openCookiePolicyModal();
        } else {
          openLoginModal();
        }
      }}
    >
      Login
    </span>
  );
};

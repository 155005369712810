import loadScript from '@/services/helpers/loadScript';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';

export const HeadScripts = () => {
  return (
    <Helmet>
      <script
        async
        src='https://www.googletagmanager.com/gtag/js?id=UA-215599420-1'
      ></script>

      <script>
        {`<!-- Google Tag Manager -->
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl+'&gtm_auth=${process.env.APP_GTM_AUTH}';f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${process.env.APP_GTM_TAG_ID}');
<!-- End Google Tag Manager -->
<!-- Global site tag (gtag.js) - Google Analytics -->
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', '${process.env.APP_GA_CODE}');
<!-- END - Global site tag (gtag.js) - Google Analytics -->
<!-- Facebook Pixel -->
!function(e,t,n,c,o,a,f){e.fbq||(o=e.fbq=function(){o.callMethod?o.callMethod.apply(o,arguments):o.queue.push(arguments)},e._fbq||(e._fbq=o),o.push=o,o.loaded=!0,o.version="2.0",o.queue=[],(a=t.createElement(n)).async=!0,a.src="https://connect.facebook.net/en_US/fbevents.js",(f=t.getElementsByTagName(n)[0]).parentNode.insertBefore(a,f))}(window,document,"script"),fbq("init","${process.env.APP_FACEBOOK_PIXEL_ID}"),fbq("track","PageView");
<!-- End Facebook pixel -->`}
      </script>
    </Helmet>
  );
};

export const BodyScripts = () => {
  useEffect(() => {
    //load liveagent chat plugin
    // loadScript('/js/livechat.js');
  }, []);

  return (
    <>
      {/* <script src='https://js.stripe.com/v3/'></script>
      <script src='https://polyfill.io/v3/polyfill.min.js?version=3.52.1&features=fetch'></script> */}
      <div
        dangerouslySetInnerHTML={{
          __html: `<!-- Google Tag Manager (noscript) --><noscript><iframe src="https://www.googletagmanager.com/ns.html?id=${process.env.APP_GTM_TAG_ID}&gtm_auth=${process.env.APP_GTM_AUTH} "height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript><!-- End Google Tag Manager (noscript) -->`,
        }}
      />
    </>
  );
};

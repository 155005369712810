export default class StoreCookie {
  private key = '';
  constructor(key: string) {
    this.key = key;
  }

  getCookie = (value = '') => {
    try {
      // Split cookie string
      var cookieArr = (value || document.cookie).split(';');
      // Loop through the array elements
      for (var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split('=');
        if (this.key == cookiePair[0].trim()) {
          return decodeURIComponent(cookiePair[1]);
        }
      }

      // Return null if not found
      return null;
    } catch (e) {
      return null;
    }
  };

  setCookie = (value: string) => {
    // Encode value in order to escape semicolons, commas, and whitespace
    let cookie = this.key + '=' + encodeURIComponent(value);
    let daysToLive = 10;

    cookie += ';path=/ ;max-age=' + daysToLive * 24 * 60 * 60;
    document.cookie = cookie;

    return { value };
  };

  removeCookie = () => {
    document.cookie =
      this.key + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  };
}

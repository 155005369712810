import { useCallback } from 'react';
import { createContext, useContext, useMemo, useState } from 'react';
import Notification from '@/components/atoms/Notification';
import { useHistory } from 'react-router';

interface ToastContextApi {
  addToast: any;
}

type ToastContextValue = ToastContextApi;

export const ToastContext = createContext<ToastContextValue>({
  addToast: null,
});

const defaultParams = {
  open: false,
  type: 'default',
  message: '',
};

const NeevToastProvider = ({ children }) => {
  const [{ open, type, message, statusCode = 200 }, setToast]: any =
    useState(defaultParams);
  const {
    location: { pathname },
  } = useHistory();

  const ignoreTop = useMemo(() => pathname === '/', [pathname]);

  const addToast = useCallback(
    (message, { statusCode = 200, appearance, autoDismiss = false }) => {
      const type = appearance == 'error' ? 'danger' : 'default';

      setToast({
        type,
        message,
        open: true,
        statusCode,
      });
    },
    [children],
  );

  const contextValue: ToastContextValue = useMemo(() => {
    return {
      addToast,
    };
  }, []);

  const isGraphqlError = ['graphql', 'variable'].findIndex(
    (e) => String(message).toLowerCase().indexOf(e) > -1,
  ) > -1;

  return (
    <ToastContext.Provider value={contextValue}>
      <Notification
        open={open}
        type={type}
        ignoreTop={ignoreTop}
        onClose={() => setToast(defaultParams)}
      >
        <span className='is-size-6'>
          {isGraphqlError
            ? 'Something went wrong. Please try again after sometime.'
            : message}
        </span>
      </Notification>
      {children}
    </ToastContext.Provider>
  );
};

export const useNeevToasts = () => useContext(ToastContext);

export default NeevToastProvider;

import { Fragment, useEffect, useState } from 'react';
import LoginModal from '@/components/molecules/Authentication/Login/LoginModal';
import SignupModal from '@/components/molecules/Authentication/Signup/SignupModal';
import ForgotPasswordModal from '@/components/molecules/Authentication/ForgotPassword/ForgotPasswordModal';
import SignupProvider from '../context/signup';
import { useAppContext } from '@/components/context/AppContext';
import { useUserContext } from '@/components/context/UserContext';
import useQueryString from '@/hooks/useQueryString';
import CookiePolicyModal from '../molecules/CookiePolicy/CookieModal';

export default () => {
  const [modal, setModal] = useState(null);
  const [trackOrderActive, setTrackActive] = useState(false);
  const [, { openLoginModal }] = useAppContext();
  const [{ isSignedIn }] = useUserContext();
  const [{ params }]: any = useQueryString();
  const [modalProps, setModalProps] = useState(null);

  const onClose = () => {
    setModal(null);
    setTrackActive(false);
  };

  useEffect(() => {
    const onModal = (modal = null, track) => {
      setModal(modal);
      setModalProps(track);
      if (Array.isArray(track) && track[0] == true) setTrackActive(track[0]);
    };
    eventEmitter.addListener('onModal', onModal);
    return () => {
      eventEmitter.removeListener('onModal', onModal);
    };
  }, []);

  useEffect(() => {
    if (params?.decodedValue?.login && !isSignedIn) {
      openLoginModal();
    } else if (isSignedIn) {
      // onClose();
    }
  }, [isSignedIn, params?.decodedValue?.login]);

  return (
    <Fragment>
      <SignupProvider>
        <LoginModal
          otpForm={trackOrderActive}
          show={modal == 'common.login'}
          onClose={onClose}
        />
        <SignupModal
          show={modal == 'common.register'}
          {...modalProps}
          onClose={onClose}
        />
        <ForgotPasswordModal
          show={modal == 'common.forgotPassword'}
          onClose={onClose}
        />
        <CookiePolicyModal
          show={modal == 'common.cookiePolicy'}
          onClose={onClose}
        />
      </SignupProvider>
    </Fragment>
  );
};

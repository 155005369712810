import Columns from '@/components/atoms/Columns';
import ButtonIconWrapper from '@/components/molecules/ButtonIconWrapper';
import { useCartContext } from '@/components/context/CartContext';
import styles from '../checkout.module.scss';
import cn from 'classnames';
import EmptyBag from '../EmptyBag';
import Container from '@/components/atoms/Container';
import CartItem from './Item';
import itemStyle from './item.module.scss';
import Price from '@/components/atoms/Price';
import { useAppContext } from '@/components/context/AppContext';

export default function CartItems({
  onClick = null,
  hide = false,
  continueCN = '',
  closePopups = null,
  miniBag = false,
  showMiniBag = false,
}: any) {
  const [{ isCartLoading, isEmpty, cartItems, cartSubTotal }]: any =
    useCartContext();
  const [{ isMobile }] = useAppContext();

  return (
    <div
      className={cn(
        itemStyle.root,
        'mb-3',
        {
          ['is-flex is-align-items-center is-justify-content-center']:
            !cartItems.length,
        },
        {
          [itemStyle['sticky-space']]:
            miniBag && showMiniBag && cartItems.length > 3,
        },
      )}
    >
      {isEmpty && !isCartLoading && (
        <EmptyBag
          onClick={() => {
            closePopups && closePopups();
          }}
        />
      )}
      <div className={cn('mt-2', styles['cart-container'])}>
        {cartItems.map((e: any, i: number) => (
          <CartItem closePopups={closePopups} counter={true} {...e} key={i} />
        ))}
      </div>
      {!isEmpty && (
        <Container
          className={cn({
            [itemStyle['sticky-continue']]:
              miniBag && showMiniBag && cartItems.length > 3,
            [itemStyle['sticky-continue-slide']]: showMiniBag,
          })}
        >
          <Columns
            breakpoint={'mobile'}
            className={cn(
              'has-text-weight-bold pt-3',
              hide && 'is-hidden-mobile',
            )}
          >
            <Columns.Column>Cart Total</Columns.Column>
            <Columns.Column className='has-text-right is-size-5'>
              <Price value={cartSubTotal} />
            </Columns.Column>
          </Columns>
          {(!isMobile || showMiniBag) && (
            <ButtonIconWrapper
              fullwidth
              className={continueCN}
              onClick={onClick}
              titleClass='f-14'
            >
              Continue
            </ButtonIconWrapper>
          )}
        </Container>
      )}
    </div>
  );
}

import Columns from '@/components/atoms/Columns';
import Container from '@/components/atoms/Container';
import Icon from '@/components/atoms/Icon';
import { Link } from 'react-router-dom';
import styles from './layout.module.scss';
import cn from 'classnames';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SvgIcon from '../atoms/icon/SvgIcon';
import { FormProvider, useForm } from 'react-hook-form';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { useAppContext } from '@/components/context/AppContext';
import Divider from '@/components/atoms/Divider';
import emailRegex from '@/services/helpers/emailRegex';
import InputField from '@/components/molecules/InputField';
import useNewsLetter from '@/hooks/useNewsLetter';

const { Column } = Columns;

const CreateLinks = ({ links }) => (
  <>
    {links.map(({ text = '', link = '' }) => (
      <div className='pb-2' key={link}>
        <Link
          className={cn(styles['line-item'], 'is-size-6-desktop f-14-mobile')}
          to={link}
        >
          {text}
        </Link>
      </div>
    ))}
  </>
);

const Footer = () => {
  const methods = useForm();
  const { handleSubmit } = methods;
  const [{ isMobile }] = useAppContext();
  const { handleSendNewsLetter } = useNewsLetter();

  return (
    <footer className={cn('footer pt-6 pb-4 px-3', isMobile && 'py-6')}>
      <Container className={cn(isMobile && 'pb-4')}>
        <Columns breakpoint={'desktop'}>
          <Column
            size={'2-desktop'}
            className={cn(
              'is-flex is-hidden-mobile pb-2 is-flex-direction-column is-align-items-center pt-0',
              styles.logo,
            )}
          >
            <h4>
              <Link title='Neev' to='/'>
                <SvgIcon
                  icon={'logo'}
                  style={{
                    minHeight: 160,
                    minWidth: 105,
                  }}
                />
              </Link>
            </h4>
          </Column>
          <Column
            size={'3-desktop'}
            className='is-flex is-flex-direction-column'
          >
            <Columns>
              <Column
                size={'8-desktop'}
                className='is-inline-flex is-7-mobile is-flex-direction-column'
              >
                <CreateLinks
                  links={[
                    {
                      text: 'About Us',
                      link: '/about',
                    },
                    {
                      text: 'Shipping and Payments',
                      link: '/shipping-delivery',
                    },
                    {
                      text: 'Contact Us',
                      link: '/contact',
                    },
                  ]}
                />
              </Column>
              <Column className='is-inline-flex is-5-mobile is-flex-direction-column'>
                <CreateLinks
                  links={[
                    {
                      text: 'Careers',
                      link: '/careers',
                    },
                    {
                      text: 'FAQs',
                      link: '/faqs',
                    },
                    {
                      text: 'Terms',
                      link: '/terms-conditions',
                    },
                  ]}
                />
              </Column>
            </Columns>
          </Column>
          <Column className='is-flex is-size-6-desktop f-14-mobile is-flex-direction-column'>
            <h3
              className={cn(
                styles['line-item'],
                styles['line-item-heading'],
                'pb-2',
              )}
            >
              Customer Care
            </h3>
            <span className={'pb-2'}>Mon to Sat - 10:00am to 6:00pm IST</span>
            <div className={'pb-2 is-flex is-align-items-center'}>
              <a target={'_blank'} href={'https://wa.me/+919136822607'}>
                <SvgIcon
                  style={{
                    display: 'inline-block',
                    verticalAlign: 'middle',
                  }}
                  icon={'whatsapp-big'}
                  size={'20'}
                />
                <span className='mx-2'>+91 9136822607</span>
              </a>
            </div>
            <div className={'pb-2 is-flex is-align-items-center'}>
              <a href={'mailto:care@neev.store'}>
                <SvgIcon
                  icon={'email'}
                  style={{
                    fill: '#d79f60',
                    display: 'inline-block',
                    verticalAlign: 'middle',
                  }}
                  size={'22'}
                />
                <span className='mx-2'>care@neev.store</span>
              </a>
            </div>
          </Column>
          <Column
            size={'4-desktop'}
            className='pt-0 is-flex is-flex-direction-column is-size-6-desktop f-14-mobile'
          >
            <div className='pb-3 is-flex is-align-items-center is-hidden-mobile'>
              <span>Follow us on:</span>
              <a
                className={'mx-4 mt-2'}
                title='Neev Instagram'
                target={'_blank'}
                href={
                  'https://www.instagram.com/neev_by_vibhutikabra/?igshid=18xpp1tu1mg32'
                }
              >
                <Icon>
                  <FontAwesomeIcon
                    className={styles['s-icon']}
                    size={'2x'}
                    icon={faInstagram}
                  />
                </Icon>
              </a>
              <a
                className={'ml-1 mt-2'}
                title='Neev Facebook'
                target={'_blank'}
                href={'https://www.facebook.com/Neevbyvibhutikabra'}
              >
                <SvgIcon icon={'fb-primary'} size={'34'} />
              </a>
            </div>
            <Divider color='#F4E7D9' className='my-4' height={1} />
            <div className='pb-2'>
              To stay updated on our new collections, offers and more.
            </div>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(handleSendNewsLetter)}>
                <InputField
                  type={'email'}
                  autoComplete='email'
                  name='email'
                  placeholder='Enter email to receive newsletter'
                  className={cn(styles['newsletter-input'], 'pl-4 pr-1 py-3')}
                  rules={{
                    required: 'Enter email to proceed.',
                    pattern: emailRegex,
                  }}
                />
                <button className={styles.subscribe}>
                  <Icon>
                    <FontAwesomeIcon color={'#c4361c'} icon={faPaperPlane} />
                  </Icon>
                </button>
              </form>
            </FormProvider>
          </Column>
        </Columns>
        <Divider className='mt-5 mb-3' color='#F4E7D9' />
        <Columns
          className={cn('is-size-6-desktop f-14-mobile')}
          breakpoint={'desktop'}
        >
          <Column>
            <span className={styles['footer-bottom']}>
              <Link to='/privacy-policy'>Privacy Policy</Link>
              <Link to='/cookies'>Cookies</Link>
            </span>
            <div className='is-hidden-desktop is-flex is-pulled-right'>
              <a
                className={'mx-4 is-inline-block v-align-middle'}
                title='Neev Instagram'
                target={'_blank'}
                href={
                  'https://www.instagram.com/neev_by_vibhutikabra/?igshid=18xpp1tu1mg32'
                }
              >
                <Icon>
                  <FontAwesomeIcon
                    className={styles['s-icon']}
                    size={'2x'}
                    icon={faInstagram}
                  />
                </Icon>
              </a>
              <a
                className={'ml-1 is-inline-block v-align-middle'}
                title='Neev Facebook'
                target={'_blank'}
                href={'https://www.facebook.com/Neevbyvibhutikabra'}
              >
                <SvgIcon icon={'fb-primary'} size={'28'} />
              </a>
            </div>
          </Column>
          <Column
            className={cn(
              styles.copyright,
              'has-text-right-desktop has-text-centered-mobile f-14',
            )}
          >
            &copy; All Rights Reserved {(new Date()).getFullYear()}. Neev by Vibhuti Kabra.
          </Column>
        </Columns>
      </Container>
    </footer>
  );
};

export default Footer;

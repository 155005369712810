import loadable from '@loadable/component';

const HomeAsync = loadable(
  () => import(/* webpackChunkName: "Home" */ './pages/Home.page'),
  {
    ssr: true,
  },
);

const CategoryAsync = loadable(
  () => import(/* webpackChunkName: "Category" */ './pages/Category'),
  {
    ssr: true,
  },
);

const ProductAsync = loadable(
  () => import(/* webpackChunkName: "Product" */ './pages/Product'),
  {
    ssr: true,
  },
);

const PageAsync = loadable(
  () => import(/* webpackChunkName: "Page" */ './pages/Page'),
  {
    ssr: true,
  },
);

const StaticAsync = loadable(
  () => import(/* webpackChunkName: "Page" */ './pages/s/Static'),
  {
    ssr: true,
  },
);

const CustomerAsync = loadable(
  () => import(/* webpackChunkName: "Login" */ './pages/customer'),
  {
    ssr: false,
  },
);

const CheckoutAsync = loadable(
  () => import(/* webpackChunkName: "Checkout" */ './pages/checkout'),
  {
    ssr: false,
  },
);

const AboutUsAsync = loadable(
  () => import(/* webpackChunkName: "Checkout" */ './pages/About'),
  {
    ssr: true,
  },
);

const ContactUsAsync = loadable(
  () => import(/* webpackChunkName: "Checkout" */ './pages/Contact'),
  {
    ssr: false,
  },
);

const CareGuideAsync = loadable(() => import('./pages/CareGuide'), {
  ssr: false,
});
const TestAsync = loadable(() => import('./pages/Test'), {
  ssr: true,
});

const CareersAsync = loadable(() => import('./pages/Careers'), {
  ssr: false,
});

const CareersFormAsync = loadable(() => import('./pages/CareersForm'), {
  ssr: false,
});

const SessionAsync = loadable(() => import('./pages/neev-talks'), {
  ssr: false,
});

const ProfileAsync = loadable(() => import('./pages/profile'), {
  ssr: false,
});

const GiftCardAsync = loadable(() => import('./pages/gift-card'), {
  ssr: false,
});

const FAQAsync = loadable(() => import('./pages/Faqs'), {
  ssr: false,
});

const FabricGuideAsync = loadable(() => import('./pages/fabric-guide'), {
  ssr: false,
});

const WorkGuideAsync = loadable(() => import('./pages/work-guide'), {
  ssr: false,
});

const TermsConditionsAsync = loadable(
  () => import('./pages/terms-conditions'),
  {
    ssr: false,
  },
);

const PrivacyPolicyAsync = loadable(() => import('./pages/privacy-policy'), {
  ssr: false,
});

const CookiesAsync = loadable(() => import('./pages/Cookies'), {
  ssr: false,
});

const ReturnExchange = loadable(
  () => import('./pages/shipping-returns/ReturnExchange'),
  { ssr: false },
);

const ShippingDelivery = loadable(
  () => import('./pages/shipping-returns/ShippingDelivery'),
  { ssr: false },
);

const SearchAsync = loadable(
  () => import(/* webpackChunkName: "Search" */ './pages/Search'),
  {
    ssr: true,
  },
);

const MagerewriteAsync = loadable(
  () => import(/* webpackChunkName: "Search" */ './pages/Magerewrite'),
  {
    ssr: true,
  },
);

const routes: IRoute[] = [
  {
    path: '/',
    exact: true,
    strict: true,
    component: HomeAsync,
    initialDataPath: 'home',
  },
  {
    path: 'CATEGORY',
    exact: true,
    strict: true,
    component: CategoryAsync,
    initialDataPath: 'category',
  },
  {
    path: 'PRODUCT',
    exact: true,
    strict: true,
    component: ProductAsync,
    initialDataPath: 'product',
  },
  {
    path: 'CMS_PAGE',
    exact: true,
    strict: true,
    component: PageAsync,
    initialDataPath: 'page',
  },
  // {
  //   path: '/s',
  //   strict: true,
  //   component: StaticAsync,
  //   initialDataPath: 's',
  // },
  // {
  //   path: '/customer',
  //   component: CustomerAsync,
  // },
  {
    path: '/checkout',
    component: CheckoutAsync,
  },
  {
    path: '/about',
    component: AboutUsAsync,
  },
  {
    path: '/contact',
    component: ContactUsAsync,
  },
  {
    path: '/care-guide',
    component: CareGuideAsync,
  },
  {
    path: '/careers',
    component: CareersAsync,
  },
  {
    path: '/careers-form',
    component: CareersFormAsync,
  },
  {
    path: '/neev-talks',
    component: SessionAsync,
  },
  {
    path: '/profile',
    component: ProfileAsync,
  },
  {
    path: '/gift-card',
    component: GiftCardAsync,
  },
  {
    path: '/faqs',
    component: FAQAsync,
  },
  {
    path: '/fabric-guide',
    component: FabricGuideAsync,
  },
  {
    path: '/work-guide',
    component: WorkGuideAsync,
  },
  {
    path: '/terms-conditions',
    component: TermsConditionsAsync,
  },
  {
    path: '/privacy-policy',
    component: PrivacyPolicyAsync,
  },
  {
    path: '/cookies',
    component: CookiesAsync,
  },
  {
    path: '/return-exchange',
    component: ReturnExchange,
  },
  {
    path: '/shipping-delivery',
    component: ShippingDelivery,
  },
  {
    path: '/search',
    exact: true,
    strict: true,
    component: SearchAsync,
    initialDataPath: 'filters'//category',
  },
  {
    path: '/filters',
    exact: true,
    strict: true,
    component: () => null,
    initialDataPath: 'filters',
  },
  {
    path: '*',
    strict: true,
    component: MagerewriteAsync,
    initialDataPath: 'magerewrite',
  },
  {
    path: '/filters',
    exact: true,
    strict: true,
    component: () => null,
    initialDataPath: 'filters',
  },
  {
    path: '*',
    strict: true,
    component: MagerewriteAsync,
    initialDataPath: 'magerewrite',
  },
];

export default routes;

import MediaGallery from '@/services/graphql/fragments/product/MediaGallery.gql';
import ProductPrice from '@/services/graphql/fragments/product/ProductPrice.gql';
import { gql } from 'graphql-request';

const ConfigurableProductFragment = gql`
fragment ConfigurableProductFragment on ConfigurableProduct {
  configurable_options {
      attribute_code
      attribute_uid
      uid
      label
      position
      use_default
      values {
          uid
          default_label
          label
          store_label
          use_default_value
          value_index
          swatch_data {
              ... on ImageSwatchData {
                  thumbnail
              }
              value
          }
      }
  }
  variants {
    attributes {
        code
        label
        uid
        value_index
      }
      product {
          uid
          sku
          name
          url_key
          image {
            url
          }
          thumbnail {
            url
          }
          description {
            html
          }
          
          media_gallery ${MediaGallery}
          sku
          stock_status
          price_range {
            minimum_price ${ProductPrice()}
            maximum_price ${ProductPrice()}
          }
      }
  }
}`;

export default ConfigurableProductFragment;

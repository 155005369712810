/**
 * Maps an error to a string message
 *
 * @param {Error} errors the error to map
 * @return {String} error message
 */
const convertErrorsToString = (errors: any) => {
  return errors.map(({ message }) => message).join(', ');
};

/**
 * Maps an error to a string message
 *
 * @param {Error} error the error to map
 * @return {String} error message
 */
const toString = (error: any) => {
  const { graphQLErrors, message, response = {} } = error;
  const { errors } = response;

  return graphQLErrors && graphQLErrors.length
    ? convertErrorsToString(graphQLErrors)
    : errors?.length
    ? convertErrorsToString(errors)
    : message;
};

/**
 * A function to derive an error string from an array of errors.
 */
export const deriveErrorMessage = (errors) => {
  const errorCollection: any = [];
  for (const error of errors) {
    if (error) {
      errorCollection.push(toString(error));
    }
  }

  if (errorCollection && errorCollection.length)
    console.log('Error', errorCollection);
  return errorCollection.join(', ');
};

import Icon from '@/components/atoms/Icon';
import { useAppContext } from '@/components/context/AppContext';
import { useSignIn } from '@/hooks/customer/useSignIn';
import { selectCustomer } from '@/store/reducers/auth.reducer';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const UserIcon = () => <FontAwesomeIcon icon={faUser} />;

export const MobileLoginButton = ({ onClose = null }: any) => {
  const { isSignedIn, handleCustomer } = useSignIn();
  const signedIn = isSignedIn();
  const customer = useSelector(selectCustomer);
  const [, { openLoginModal }] = useAppContext();

  useEffect(() => {
    if (signedIn) handleCustomer({ withCart: true });
  }, [signedIn, customer?.created_at]);

  if (signedIn && customer?.created_at) {
    return (
      <Link
        to='/profile'
        title='Profile'
        onClick={() => {
          onClose && onClose();
        }}
      >
        <Icon className='ml-1 mt-2'>
          <UserIcon />
        </Icon>
      </Link>
    );
  }

  return (
    <Icon
      onClick={() => {
        openLoginModal();
        onClose && onClose();
      }}
      className='ml-1 mt-2'
    >
      <UserIcon />
    </Icon>
  );
};

import StoreCookie from './cookie';

const key = 'sitecurrency';
const storage = new StoreCookie(key);

export const removeCurrency = () => {
  return storage.removeCookie();
};
export const getCurrency = (cookie = '') => {
  return storage.getCookie(cookie) || 'INR';
};
export const setCurrency = (currency: string) => {
  storage.setCookie(currency);
};

export const getCurrencyHeaders = (cookie?: string) => {
  return {
    headers: {
      'Content-Currency': storage.getCookie(cookie) || 'INR',
    },
  };
};

import { ElementProps } from '@/components/atoms/Element';
import { FC, HTMLProps, ReactPropTypes, Ref } from 'react';

interface CheckboxProps extends HTMLProps<HTMLInputElement> {
  name: string;
  style?: any;
  disabled?: boolean;
  value?: string;
  checked?: boolean;
  defaultChecked?: boolean;
  domRef?: Ref<any>;
  getRef?: Ref<any>;
  id: string;
}

export type ICheckbox = FC<CheckboxProps>;
const Checkbox: ICheckbox = ({
  className,
  style,
  children,
  domRef,
  id,
  ref,
  getRef,
  ...props
}) => {
  return (
    <>
      <input
        {...props}
        type='checkbox'
        className='is-checkbox'
        ref={getRef}
        id={id}
      />
      <label ref={domRef} className={className} style={style} htmlFor={id}>
        {children}
      </label>
    </>
  );
};

export default Checkbox;

import Columns from '@/components/atoms/Columns';
import Counter from '@/components/molecules/Counter';
import { FormProvider, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import styles from '../checkout.module.scss';
import item from './item.module.scss';

import cn from 'classnames';
import { SUPPORTED_PRODUCT_TYPES } from '@/hooks/ProductDetail/useProductDetail';
import { QuickLoader } from '@/components/loader/Loader';
import { useSelector } from 'react-redux';
import { useMemo, Fragment } from 'react';

const BundleOptionsItem = ({
  label,
  quantity,
  mtr,
  quantity_ordered,
  fabricProduct,
}) => {
  const isDupatta = String(label).toLowerCase() == 'dupatta';

  const value = useMemo(() => {
    if (quantity_ordered > 1 && !isDupatta) {
      const value: any = [];
      for (var i = 1; i <= quantity_ordered; i++) {
        value.push(
          <Fragment key={i}>
            {quantity}
            {!!mtr && (
              <span className={'is-size-7 my-1 mr-1 is-lowercase silver'}>
                {' '}
                meter
              </span>
            )}
          </Fragment>,
        );

        if (i > 0 && i < quantity_ordered) value.push('+ ');
      }

      return value;
    }

    return (
      <>
        {isDupatta ? quantity_ordered : quantity}
        {!!mtr && !isDupatta && (
          <span className={'is-size-7 my-1 mr-1 is-lowercase silver'}>
            {' '}
            meter
          </span>
        )}
      </>
    );
  }, [quantity_ordered]);

  return (
    <div className='f-12'>
      <span className='is-grey'>{label} :</span> {value}
    </div>
  );
};

export const CartItemMeta = ({
  product,
  attributes,
  quantity = 1,
  updateCartItem,
  editProduct,
  selectedVariant,
  uid,
  summary,
  isCartItemUpdating,
  closePopups,
  giftCardOptions,
}) => {
  const methods = useForm();
  const isProductTypeSupported = SUPPORTED_PRODUCT_TYPES.includes(
    product.__typename,
  );
  const { updatingCartItem, loading } = useSelector(
    (s: any) => ({ ...s?.cart } || {}),
  );
  const isCartItemLoading = useMemo(
    () => updatingCartItem == uid || updatingCartItem == product?.sku,
    [product, updatingCartItem],
  );

  return (
    <Columns.Column
      className='is-flex is-flex-direction-column'
      desktop={{
        size: '8',
      }}
      mobile={{
        size: '12',
      }}
    >
      {isProductTypeSupported ? (
        <Link
          className='is-dark-grey pt-1 f-14 has-text-weight-bold'
          to={`/${product.url_key}${product.url_suffix}`}
          title={product.name}
          onClick={(e) => {
            closePopups && closePopups();
          }}
        >
          {product.name}
          {/* {attributes?.value_label && ` - ${attributes?.value_label}`} */}
        </Link>
      ) : (
        <span className='is-dark-grey pt-1 f-14 has-text-weight-bold'>
          {product.name}
        </span>
      )}
      <div className='mt-auto'>
        {!summary &&
          (product.__typename === 'AmGiftCardProduct')
          // || product.__typename !== 'BundleProduct')
          && (
            <div className={cn(item.counter, 'pt-2 ternary-color f-12')}>
              Quantity: {quantity}
            </div>
          )}
        {!summary && product.__typename === 'ConfigurableProduct' && (
          <div className={cn(item.counter)}>
            <div className='is-ternary pt-1 f-12'>Pieces</div>
            <div
              className={cn(
                'is-flex-desktop pt- is-align-items-center is-justify-content-space-between',
                styles.pieces,
              )}
            >
              {isCartItemLoading || isCartItemUpdating || loading ? (
                <QuickLoader className={'ml-5 mt-2'} size={22} />
              ) : (
                <FormProvider {...methods}>
                  <Counter
                    name='cartItems[0].quantity'
                    size='small'
                    ghostBorder
                    defaultValue={quantity}
                    onChange={updateCartItem}
                    preventZeroQty
                  />
                </FormProvider>
              )}
            </div>
          </div>
        )}
        {giftCardOptions &&
          giftCardOptions.map((e) => (
            <div className='is-grey is-size-7' key={e.code}>
              {e.label}: {e.value}
            </div>
          ))}
        {(editProduct || summary) && (
          <div className='py-1'>
            {Array.isArray(selectedVariant?.bundleOptions) &&
              selectedVariant.bundleOptions.map(({ label, qty }, i: number) => (
                // <div key={i} className='f-12'>
                //   <span className='is-grey'>{label} :</span>{' '}
                //   <span className='px-1'>{qty}</span>
                //   <span className='is-grey'>meter</span>
                // </div>
                <BundleOptionsItem
                  {...{
                    key: i,
                    label,
                    quantity: qty,
                    quantity_ordered: quantity,
                    mtr: true,
                    fabricProduct: selectedVariant?.fabricProduct,
                  }}
                />
              ))}
            {summary && product.__typename !== 'BundleProduct' && (
              <div className='f-12 mt-3'>
                <div className='is-grey'>Item Code: {product.sku}</div>
                <div className='ternary-color pt-1'>Quantity: {quantity}</div>
              </div>
            )}
            {!summary && (
              <Link
                onClick={() => closePopups && closePopups()}
                to={`/checkout/cart/configure/id/${uid}/product_id/${product.url_key}/`}
                className='f-14 pt-2 is-brown has-text-weight-bold is-block'
              >
                Edit
              </Link>
            )}
          </div>
        )}
      </div>
    </Columns.Column>
  );
};

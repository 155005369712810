import { gql } from 'graphql-request';
import { ProductDetailAttributes } from '@/services/graphql/queries/Product.gql/ProductDetailAttributes.gql';

export const ProductDetailsFragment = gql`
  fragment ProductDetailsFragment on ProductInterface {
    ${ProductDetailAttributes}
  }
`;

export default ProductDetailsFragment;

import PersistanceStorage from '.';
import { getCookieConsentIsDeclined } from './cookie-consent';

const storage = new PersistanceStorage();

export const removeToken = () => {
  return storage.removeItem('token');
};
export const getToken = () => {
  if (getCookieConsentIsDeclined()) {
    removeToken();
    return;
  }

  return storage.getItem('token');
};
export const setToken = (token, ttl?) => {
  // if (typeof window !== 'undefined' && getCookieConsentIsDeclined()) {
  //   alert('Cookie consent is required to avail all services of website');
  // }

  if (getCookieConsentIsDeclined()) {
    removeToken();
    return;
  }

  storage.setItem('token', token, ttl);
};

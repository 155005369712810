import CoreForm from 'src/core/components/atoms/Form';
import Radio from './components/Radio';
import Checkbox from './components/Checkbox';
import './form.scss';

const CurrentForm = {
  Checkbox,
  Radio,
};
type ICoreForm = Omit<typeof CoreForm, 'Checkbox' | 'Radio'>;
const Form: ICoreForm & typeof CurrentForm = {
  ...CoreForm,
  ...CurrentForm,
};

export default Form;

// export default FormBuilder;
// Form.Radio = Radio;
// Form.Checkbox = Checkbox;
// export default Form;

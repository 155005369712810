import { FC, Fragment, useEffect } from 'react';
import Header from '@/components/Layout/Header';
import Footer from '@/components/Layout/Footer';
import CommonModals from '@/components/Layout/CommonModals';
import Loader from '@/components/loader/Loader';
import { HeadScripts, BodyScripts } from '@/components/Layout/Scripts';

const Layout: FC<any> = ({ children, isLoading = false }) => {
  return (
    <Fragment>
      <HeadScripts />
      {isLoading && <Loader />}
      <Header />
      <main>{children}</main>
      <Footer />
      <CommonModals />
      <BodyScripts />
    </Fragment>
  );
};

export default Layout;

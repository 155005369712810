import Columns from '@/components/atoms/Columns';
import Input from '@/components/atoms/Form/components/Input';
import SvgIcon from '@/components/atoms/icon/SvgIcon';
import cn from 'classnames';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import styles from './search-bar.module.scss';
import querystring from 'querystring';
import { useEffect, useRef } from 'react';

export default function SearchBar({ toggle = false, close, headerSwitched }) {
  const history = useHistory();
  const searchInputRef = useRef<HTMLInputElement | null>(null);
  const { register, handleSubmit, reset } = useForm();

  useEffect(() => {
    if (searchInputRef.current) {
      register(searchInputRef.current)
      searchInputRef.current.focus()
    }
  }, [toggle])

  function onFormSubmit(d) {
    history.push('/search?' + querystring.encode({ q: d.search }));

    close();
    reset();
  }

  return (
    <>
      <div
        className={cn(
          styles.root,
          'px-4 ',
          { [styles.toggle]: toggle },
          { [styles['root-home-page']]: !headerSwitched },
        )}
      >
        <Columns
          vCentered
          breakpoint='mobile'
          className={styles.columns}
          gapless
        >
          <Columns.Column>
            {/* <FormProvider {...methods}> */}
            <form onSubmit={handleSubmit(onFormSubmit)}>
              <Columns gapless breakpoint='mobile' vCentered>
                <Columns.Column narrow>
                  <span className='px-3'>
                    <SvgIcon
                      onClick={handleSubmit(onFormSubmit)}
                      icon={'search-grey'}
                      size='24'
                    />
                  </span>
                </Columns.Column>
                <Columns.Column>
                  <Input
                    name='search'
                    getRef={(e) => {
                      register(e, { required: true })
                      searchInputRef.current = e
                    }}
                    className={styles.input}
                    placeholder='Search for products, collections and more.'
                  />
                </Columns.Column>
              </Columns>
            </form>
            {/* </FormProvider> */}
          </Columns.Column>
          <Columns.Column narrow>
            <span className='px-3'>
              <SvgIcon onClick={close} icon={'close'} size='24' />
            </span>
          </Columns.Column>
        </Columns>
      </div>
      {toggle && <div onClick={close} className={cn(styles.close)} />}
    </>
  );
}

import Columns from '@/components/atoms/Columns';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';
import styles from './HeaderTop.module.scss';
import { useEffect, useState } from 'react';
import { Fragment, createRef } from 'react';
import {
  AnimatedMobileLogo,
  BannerMessage,
  CartBag,
  Logo,
  MobileCreateAccountContainer,
  Search,
  SelectCurrency,
  ShopLink,
  LoginButton,
} from './components';
import { useCartContext } from '@/components/context/CartContext';
import { useAppContext } from '@/components/context/AppContext';

const ref: any = createRef();
const HeaderTop = ({ handleMenuVisibility, toggleMiniBag, currency }: any) => {
  const [opacity, setOpacity] = useState<any>(false);
  const [{ totalCartItems }, { handleGetCustomerCart }]: any = useCartContext();
  const [{ isMobile }] = useAppContext();
  const {
    location: { pathname },
  } = useHistory();

  //switch header on route change to differ from homepage
  const headerSwitched = pathname !== '/';
  const color = headerSwitched ? 'ternary-color' : 'has-text-white';

  useEffect(() => {
    handleGetCustomerCart({
      billing: true,
      shipping: true,
      paymentMethod: true,
      shippingMethod: true,
      availableShippingMethods: true,
      availablePaymentMethods: true,
    });

    //hide menu if clicked anywhere in document
    const listner = () => {
      handleMenuVisibility(false);
      toggleMiniBag(false);
    };
    window.addEventListener('click', listner);

    const maxScroll = document.body.scrollHeight;
    var scrolling = true;
    var currentScrollPos = window.pageYOffset;

    const updateState = () => {
      if (window.screen.width <= 500) {
        if (
          currentScrollPos >= window.innerHeight &&
          currentScrollPos < maxScroll
        ) {
          setOpacity(true);
        } else {
          setOpacity(false);
        }
      }
    };
    const scrollListener = () => {
      currentScrollPos = window.scrollY;
      //TODO - what was it for identify and resolve
      /* if (scrolling) {
        return;
      } */
      scrolling = true;
      requestAnimationFrame(updateState);
    };

    window.addEventListener('scroll', scrollListener);

    return () => {
      window.removeEventListener('click', listner);
      window.removeEventListener('scroll', scrollListener);
    };
  }, []);

  useEffect(() => {
    if (ref.current) {
      if (headerSwitched) {
        document.body.style.paddingTop = `${ref.current.clientHeight}px`;
      } else {
        document.body.style.paddingTop = `0`;
      }
    }
  }, [headerSwitched]);

  return (
    <Fragment>
      <div
        ref={ref}
        className={cn(
          styles.root,
          'px-4 header-top',
          opacity && styles.shadow,
          headerSwitched && styles['header-secondary'],
          headerSwitched ? 'py-4' : 'py-3',
        )}
        onClick={() => {
          handleMenuVisibility(false);
          toggleMiniBag(false);
        }}
      >
        <Columns
          vCentered
          centered
          breakpoint={'mobile'}
          className={cn('has-text-white', {
            'py-0': !(isMobile && !headerSwitched),
            [styles['fixed-banner']]: isMobile && !headerSwitched,
          })}
        >
          <ShopLink
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleMenuVisibility(true);
              toggleMiniBag(false);
            }}
            className={cn(color, 'has-text-weight-bold')}
          />

          {pathname === '/' && <BannerMessage hide={headerSwitched} />}

          <Logo hide={headerSwitched} />

          {isMobile && pathname === '/' && (
            <AnimatedMobileLogo show={opacity} hide={headerSwitched} />
          )}

          <Columns.Column
            className={cn(
              'is-uppercase has-text-right py-0 is-flex is-align-items-center is-justify-content-flex-end pr-5 has-text-weight-bold',
              color,
            )}
          >
            <Search toggle={headerSwitched} />

            <SelectCurrency
              parentClassName={cn(
                'is-hidden-mobile',
                styles['select-gap'],
                !headerSwitched && styles.select,
              )}
              className={cn(color, 'has-text-weight-bold')}
              options={currency.list}
              defaultOption={currency.default}
            />

            {!isMobile && <LoginButton />}

            <CartBag
              count={totalCartItems}
              onClick={(e: any) => {
                e.stopPropagation();
                toggleMiniBag(true);
                handleMenuVisibility(false);
              }}
              toggle={headerSwitched}
            />
          </Columns.Column>
        </Columns>
      </div>
      {/* Mobile create account */}
      {!headerSwitched && <MobileCreateAccountContainer />}
    </Fragment>
  );
};

export default HeaderTop;

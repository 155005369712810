import Columns from '@/components/atoms/Columns';
import { useAppContext } from '@/components/context/AppContext';
import cn from 'classnames';
import { Delete, SaveUnSave } from '@/components/product/ProductAction';
import { useMemo } from 'react';
import Price from '@/components/atoms/Price';
import { numberNotation } from '@/services/helpers/numberNotation';
import { getCurrency } from '@/services/storage/currency';

export const CartItemPrice = ({
  quantity = 1,
  prices,
  product,
  handleRemoveCartItem,
  uid,
  summary,
}) => {
  const [{ isMobile }] = useAppContext();
  const isNotGiftCard = useMemo(
    () => product?.__typename !== 'AmGiftCardProduct',
    [product],
  );

  return (
    <Columns.Column
      desktop={{
        size: '4',
      }}
      mobile={{
        size: '12',
      }}
      className={cn('is-justify-content-space-between is-flex', {
        'is-flex-direction-column': !isMobile,
      })}
    >
      <span
        title={String(
          numberNotation({
            value: quantity * prices.price.value,
            currency: getCurrency(),
          }),
        )}
        className='ellipsis is-size-6 pr-2 pt-2 has-text-weight-bold is-block has-text-right'
      >
        <Price value={quantity * prices.price.value} />
      </span>
      {!summary && (
        <div
          style={{ maxWidth: 80, marginLeft: 'auto' }}
          className='is-flex mt-auto is-justify-content-space-between pb-1'
        >
          {isNotGiftCard && (
            <SaveUnSave
              className='mr-4'
              {...{
                productId: product?.id,
                getPayload: () => {
                  return {
                    product,
                    payload: {
                      cartItems: [
                        {
                          sku: product?.sku,
                          quantity: 1,
                        },
                      ],
                    },
                  };
                },
              }}
            />
          )}
          <Delete
            onClick={() =>
              handleRemoveCartItem({
                cart_item_id: uid,
              })
            }
          />
        </div>
      )}
    </Columns.Column>
  );
};

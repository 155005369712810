import querystring, { decode } from 'querystring';
import { useMemo } from 'react';
import { useHistory } from 'react-router';

const useQueryString = () => {
  const {
    location: { search },
  } = useHistory();

  const onEncode = (v) => {
    return querystring.encode({ ...v });
  };

  const onDecode = useMemo(
    () => search && decode(search.replace('?', '')),
    [search],
  );

  const params = useMemo(() => {
    return {
      decodedValue: onDecode,
      encodedValue: onEncode({ ...onDecode }),
    };
  }, [search]);

  return [{ params }, { onEncode }];
};

export default useQueryString;

import { useState } from 'react';
import styles from './HeaderMenu.module.scss';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import SvgIcon from '@/components/atoms/icon/SvgIcon';
import { useAppContext } from '@/components/context/AppContext';

export const NavItem = ({
  c,
  children = null,
  className = '',
  icon = false,
  onClick = null,
  onMouseEnter = null,
  onMouseLeave = null,
  url = '',
  l1 = false,
  l2 = false,
  l3 = false,
  containerCN = '',
}: any) => {
  const [open, setOpen] = useState(true);
  const [activeIcon, setActiveIcon] = useState(false);
  const [{ isMobile, isDesktop }] = useAppContext();

  if(c.include_in_menu == 0) return null;

  return (
    <div
      onMouseEnter={() => {
        setActiveIcon(true);
        onMouseEnter && onMouseEnter();
      }}
      onMouseLeave={() => {
        setActiveIcon(false);
        onMouseLeave && onMouseLeave();
      }}
      className={cn(
        {
          [styles['navItem']]: true,
          // [styles['hide-navItem']]: !open,
          [styles['active-section']]: !open,
        },
        containerCN,
      )}
    >
      <Link
        onClick={(e) => {
          if (l2 && !isMobile && c?.is_anchor != 0) {
            e && e.preventDefault();
            setOpen(!open);
          } else onClick && onClick(e);
        }}
        className={cn(
          className,
          'is-flex is-justify-content-space-between is-align-items-center',
          {
            [styles['l2-link']]: !!l2,
            ['pl-5']: l2 && (activeIcon || !open),
            ['has-text-weight-bold']: !l1 && (activeIcon || !open),
          },
        )}
        to={url}
      >
        <div className='is-flex is-justify-content-space-between is-align-items-center'>
          {!isMobile && !l1 && icon && (
            //TODO - this needs to be revamped - svg sizes are not properly shown
            <SvgIcon
              className='mt-2'
              icon={'path-right'}
              size={'16'}
              style={{
                transform: 'translateY(6px)',
                visibility: activeIcon || !open ? 'visible' : 'hidden',
              }}
            />
          )}

          <span
            className={cn({
              ['pl-3 ml-1']: !!l3,
            })}
          >
            {c.name}
          </span>
        </div>
        {!isMobile && !l1 && icon && (
          <span
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setOpen(!open);
            }}
          >
            <SvgIcon
              className='mt-2'
              icon={open ? 'arrow-dropdown' : 'arrow-dropup'}
              size={'24'}
              style={{
                visibility: activeIcon || !open ? 'visible' : 'hidden',
              }}
            />
          </span>
        )}
      </Link>

      {children}
    </div>
  );
};

import { useAppContext } from '@/components/context/AppContext';
import customerGrapqhl from '@/services/graphql/fragments/customer/customer.gql';
import { deriveErrorMessage } from '@/services/helpers/deriveErrorMessage';
import { selectCustomer } from '@/store/reducers/auth.reducer';
import { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useGraphQLRequest from '../useGraphQLRequest';
import { setConfig, useSignIn } from './useSignIn';
import { useNeevToasts } from '@/components/context/toast';
import { reloadCustomer } from '@/store/reducers/auth.reducer';

export const useCustomer = (props?: any) => {
  const { mutations } = customerGrapqhl;
  const {
    removeAddressMutation = mutations.removeAddressMutation,
    updateAddressMutation = mutations.updateAddressMutation,
    createAddressMutation = mutations.createAddressMutation,
    removeProductFromWishlistMutation = mutations.removeProductFromWishlistMutation,
  } = props || {};
  const customer = useSelector((state: any) => ({ ...state.auth.customer }));
  const [{ handleLoader }] = useAppContext();
  const { handleCustomer } = useSignIn();
  const { addToast } = useNeevToasts();
  const dispatch = useDispatch();

  /**   Create/update/remove customer address   */
  const [
    removeCustomerAddress,
    {
      error: errorRemovingAddress,
      loading: isRemovingAddress,
      data: removeCustomerData,
    },
  ] = useGraphQLRequest({ query: removeAddressMutation });

  const [
    updateAddress,
    {
      error: errorUpdatingAddress,
      loading: isUpdatingAddress,
      data: updateAddressData,
    },
  ] = useGraphQLRequest({ query: updateAddressMutation });

  const [
    createAddress,
    {
      error: errorCreatingAddress,
      loading: isCreatingAddress,
      data: createdAddressData,
    },
  ] = useGraphQLRequest({ query: createAddressMutation });

  const handleRemoveCustomerAddress = useCallback(
    async ({ id }) => {
      try {
        handleLoader(true);

        const d = await removeCustomerAddress(
          {
            id,
          },
          setConfig(),
        );

        if (d) {
          addToast('Address deleted successfully.', {
            appearance: 'success',
            autoDismiss: true,
          });
          await handleCustomer({});
        } else {
          addToast('Unable to delete the address. Please try again.', {
            appearance: 'error',
            autoDismiss: true,
          });
        }

        handleLoader(false);
        return d;
      } catch (e) {
        handleLoader(false);
      }
    },
    [removeCustomerAddress],
  );

  const handleCreateCustomerAddress = useCallback(
    async (customerAddress, { onSuccess, onError }: any) => {
      try {
        handleLoader(true);

        const d = await createAddress(
          {
            customerAddress,
          },
          setConfig(),
        );

        if (d?.createCustomerAddress?.id) {
          onSuccess && onSuccess(d);
          addToast('Address created successfully.', {
            appearance: 'success',
            autoDismiss: true,
          });
          await handleCustomer({});
        } else {
          onError && onError(d);
          addToast('Unable to create address. Please try again.', {
            appearance: 'error',
            autoDismiss: true,
          });
        }

        handleLoader(false);
        return d;
      } catch (e) {
        onError && onError();
        handleLoader(false);
      }
    },
    [createAddress],
  );

  const handleUpdateCustomerAddress = useCallback(
    async (
      { id, ...customerAddress },
      {
        successMessage = 'Address updated successfully.',
        onSuccess,
        onError,
      }: any,
    ) => {
      try {
        handleLoader(true);

        const d = await updateAddress(
          {
            id,
            customerAddress,
          },
          setConfig(),
        );

        if (d) {
          onSuccess && onSuccess();
          successMessage &&
            addToast(successMessage, {
              appearance: 'success',
              autoDismiss: true,
            });
          await handleCustomer({});
        } else {
          onError && onError();

          addToast('Unable to updated the address. Please try again.', {
            appearance: 'error',
            autoDismiss: true,
          });
        }

        handleLoader(false);
        return d;
      } catch (e) {
        onError && onError();
        handleLoader(false);
        return;
      }
    },
    [updateAddress],
  );
  /**   Create/update/remove customer address   */

  /**   list/remove/check_if_product_exists_in_wishlist customer wishlist   */
  const handleGetCustomerWishlist = useCallback(() => {
    if (Array.isArray(customer?.wishlist?.items_v2?.items)) {
      const items = [...customer?.wishlist?.items_v2?.items];
      return items.sort((a: any, b: any) => b.id - a.id);
    }

    return [];
  }, [customer?.wishlist]);

  const handleCheckIfProductExistsInWishlist = useCallback(
    ({ id, wishlist }) => {
      let items = (wishlist || customer?.wishlist)?.items_v2?.items || [];
      items = items?.filter((e: any, i: number) => e.product?.id == id);

      const flag = !!items?.length;
      return [flag, items];
    },
    [customer?.wishlist?.items_v2?.items],
  );

  const [
    removeProductFromWishlist,
    {
      error: errorRemovingProductFromWislit,
      loading: isRemovingProductFromWislist,
      data: customerWishlist,
    },
  ] = useGraphQLRequest({ query: removeProductFromWishlistMutation });

  const handleRemoveProductFromWishlist = useCallback(
    async ({ id }, { onSuccess, onError }: any) => {
      try {
        const wishlistId = customer?.wishlist?.id;
        const [, wishlistItem] = handleCheckIfProductExistsInWishlist({ id });
        const [{ id: wishlistItemId }] = wishlistItem;

        if (wishlistId && wishlistItemId) {
          handleLoader(true);

          const d = await removeProductFromWishlist(
            {
              wishlistId,
              wishlistItemId,
            },
            setConfig(),
          );

          if (
            d.removeProductsFromWishlist &&
            !d?.removeProductsFromWishlist?.user_errors?.[0]
          ) {
            const wishlist = d?.removeProductsFromWishlist?.wishlist;
            wishlist && dispatch(reloadCustomer({ wishlist }));
            onSuccess && onSuccess();

            addToast('Product removed from wishlist.', {
              appearance: 'success',
              autoDismiss: true,
            });
          } else {
            onError && onError();
            addToast(
              'Unable to remove product from wishlist. Please try again.',
              {
                appearance: 'error',
                autoDismiss: true,
              },
            );
          }

          handleLoader(false);
          return d;
        }
      } catch (e) {
        onError && onError();
        handleLoader(false);
        return;
      }
    },
    [removeProductFromWishlistMutation, customer],
  );

  const isLoading = useMemo(
    () =>
      isRemovingAddress ||
      isCreatingAddress ||
      isUpdatingAddress ||
      isRemovingProductFromWislist,
    [
      isRemovingAddress,
      isCreatingAddress,
      isUpdatingAddress,
      isRemovingProductFromWislist,
    ],
  );
  const error = useMemo(
    () =>
      deriveErrorMessage([
        errorRemovingAddress,
        errorCreatingAddress,
        errorUpdatingAddress,
        errorRemovingProductFromWislit,
      ]),
    [
      errorRemovingAddress,
      errorCreatingAddress,
      errorUpdatingAddress,
      errorRemovingProductFromWislit,
    ],
  );
  const data = useMemo(() => {
    return {
      removeCustomerData,
      createdAddressData,
      updateAddressData,
      customerWishlist,
    };
  }, [
    removeCustomerData,
    createdAddressData,
    updateAddressData,
    customerWishlist,
  ]);

  const value = useMemo(() => {
    return [
      { customer: customer || {}, isLoading, error, data },
      {
        handleRemoveCustomerAddress,
        handleUpdateCustomerAddress,
        handleCreateCustomerAddress,
        handleCheckIfProductExistsInWishlist,
        handleGetCustomerWishlist,
        handleRemoveProductFromWishlist,
      },
    ];
  }, [customer, isLoading, data, error]);

  return value;
};

import Button from '@/components/atoms/Button';
import IconsWrapper from '@/components/atoms/IconsWrapper';
import SvgIcon from '@/components/atoms/icon/SvgIcon';
import cn from 'classnames';
import styles from './styles.module.scss';

const ButtonIconWrapper = ({
  className = null,
  onClick = null,
  titleClass = '',
  children = 'Add to bag',
  fullwidth = false,
  type = null,
  disabled = false,
}: any) => {
  return (
    <IconsWrapper
      icon={'white'}
      as={Button}
      type={type}
      disabled={disabled}
      className={cn(
        'is-uppercase is-primary is-flex is-align-items-center',
        styles.root,
        className,
      )}
      fullwidth={fullwidth}
      size={'medium'}
      onClick={onClick}
      // TODO - configure svgs to have proper height and widths
      leftIcon={<SvgIcon size='12' icon='path-left-white' />}
      rightIcon={<SvgIcon size='12' icon='path-right-white' />}
      leftIconCN={'mr-2 is-flex is-align-items-center'}
      rightIconCN={'ml-2 is-flex is-align-items-center'}
    >
      <span className={titleClass}>{children}</span>
    </IconsWrapper>
  );
};

export default ButtonIconWrapper;

import PersistanceStorage from '.';
import { getToken } from './user';
import { getCookieConsentIsDeclined } from './cookie-consent';

const storage = new PersistanceStorage();
export const getUserCartId = () => {
  return storage.getItem('user-cart');
};
export const getGuestCartId = () => {
  return storage.getItem('guest-cart');
};
export const getCartId = () => {
  if (getToken()) {
    return getUserCartId();
  }
  return getGuestCartId();
};
export const setCartId = (cartId) => {
  if (getToken()) {
    return storage.setItem('user-cart', cartId);
  }
  storage.setItem('guest-cart', cartId);
};
export const removeCartId = (guest = false) => {
  if (getToken() && !guest) {
    storage.removeItem('user-cart');
  }
  storage.removeItem('guest-cart');
};

import { appendOptionsToPayload } from '@/services/helpers/appendOptionsToPayload';
import { deriveErrorMessage } from '@/services/helpers/deriveErrorMessage';
import { findMatchingVariant } from '@/services/helpers/findMatchingProductVariant';
import { isProductConfigurable } from '@/services/helpers/isProductConfigurable';
import { getCartId } from '@/services/storage/cart';
import { reloadCustomer } from '@/store/reducers/auth.reducer';
import { useCallback, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNeevToasts } from '@/components/context/toast';
import { useCart } from '../checkout/useCart';
import { setConfig } from '../customer/useSignIn';
import useGraphQLRequest from '../useGraphQLRequest';

export const SUPPORTED_PRODUCT_TYPES = [
  'SimpleProduct',
  'ConfigurableProduct',
  'BundleProduct',
];
const INITIAL_OPTION_CODES = new Map();
const INITIAL_OPTION_SELECTIONS = new Map();

const deriveOptionCodesFromProduct = (product) => {
  // If this is a simple product it has no option codes.
  if (!isProductConfigurable(product)) {
    return INITIAL_OPTION_CODES;
  }

  // Initialize optionCodes based on the options of the product.
  const initialOptionCodes = new Map();
  for (const { attribute_id, attribute_code } of product.configurable_options) {
    initialOptionCodes.set(attribute_id, attribute_code);
  }

  return initialOptionCodes;
};

// Similar to deriving the initial codes for each option.
const deriveOptionSelectionsFromProduct = (product) => {
  if (!isProductConfigurable(product)) {
    return INITIAL_OPTION_SELECTIONS;
  }

  const initialOptionSelections = new Map();
  for (const { attribute_id } of product.configurable_options) {
    initialOptionSelections.set(attribute_id, undefined);
  }

  return initialOptionSelections;
};

const getIsMissingOptions = (product, optionSelections) => {
  // Non-configurable products can't be missing options.
  if (!isProductConfigurable(product)) {
    return false;
  }

  // Configurable products are missing options if we have fewer
  // option selections than the product has options.
  const { configurable_options } = product;
  const numProductOptions = configurable_options.length;
  const numProductSelections = Array.from(optionSelections.values()).filter(
    (value) => !!value,
  ).length;

  return numProductSelections < numProductOptions;
};

const getMediaGalleryEntries = (product, optionCodes, optionSelections) => {
  let value = [];

  const { media_gallery: media_gallery_entries, variants } = product;
  const isConfigurable = isProductConfigurable(product);

  // Selections are initialized to "code => undefined". Once we select a value, like color, the selections change. This filters out unselected options.
  const optionsSelected =
    Array.from(optionSelections.values()).filter((value) => !!value).length > 0;

  if (!isConfigurable || !optionsSelected) {
    value = media_gallery_entries;
  } else {
    // If any of the possible variants matches the selection add that
    // variant's image to the media gallery. NOTE: This _can_, and does,
    // include variants such as size. If Magento is configured to display
    // an image for a size attribute, it will render that image.
    const item = findMatchingVariant({
      optionCodes,
      optionSelections,
      variants,
    });

    value = item
      ? [...item.product.media_gallery_entries, ...media_gallery_entries]
      : media_gallery_entries;
  }

  return value;
};

const getConfigPrice = (product, optionCodes, optionSelections) => {
  let value;

  const { variants } = product;
  const isConfigurable = isProductConfigurable(product);

  const optionsSelected =
    Array.from(optionSelections.values()).filter((value) => !!value).length > 0;

  if (!isConfigurable || !optionsSelected) {
    value = product?.price_range?.minimum_price.regular_price.value;
  } else {
    const item = findMatchingVariant({
      optionCodes,
      optionSelections,
      variants,
    });

    value = item
      ? item.product.price_range?.minimum_price.regular_price.value
      : product.price_range?.minimum_price.regular_price.value;
  }

  return value;
};

export const useProductDetail = (props) => {
  const { handleEmptyCart } = useCart({});
  const customer = useSelector((state: any) => ({ ...state.auth.customer }));
  const dispatch = useDispatch();
  const { addToast } = useNeevToasts();

  const {
    addBundleProductToCartMutation,
    addConfigurableProductToCartMutation,
    addSimpleProductToCartMutation,
    addProductToWishListMutation,
  } = props;

  let { product } = props;

  //set to avoid run time errors for other product types
  if (product && !product?.configurable_options) {
    product = {
      configurable_options: [],
      ...product,
    };
  }

  const productType = product.__typename;
  const isProductTypeSupported = SUPPORTED_PRODUCT_TYPES.includes(productType);

  const [
    addConfigurableProductToCart,
    {
      error: errorAddingConfigurableProduct,
      loading: isAddConfigurableLoading,
    },
  ] = useGraphQLRequest({ query: addConfigurableProductToCartMutation });

  const [
    addSimpleProductToCart,
    { error: errorAddingSimpleProduct, loading: isAddSimpleLoading },
  ] = useGraphQLRequest({ query: addSimpleProductToCartMutation });

  const [
    addBundleProductToCart,
    { error: errorBundleProduct, loading: isBundleProductLoading },
  ] = useGraphQLRequest({ query: addBundleProductToCartMutation });

  const [
    addProductToWishList,
    { error: errorWishlist, loading: isWishlistLoading },
  ] = useGraphQLRequest({ query: addProductToWishListMutation });

  const derivedOptionSelections = useMemo(
    () => deriveOptionSelectionsFromProduct(product),
    [product],
  );

  const [optionSelections, setOptionSelections] = useState(
    derivedOptionSelections,
  );

  const derivedOptionCodes = useMemo(
    () => deriveOptionCodesFromProduct(product),
    [product],
  );

  const [optionCodes] = useState(derivedOptionCodes);

  const isMissingOptions = useMemo(
    () => getIsMissingOptions(product, optionSelections),
    [product, optionSelections],
  );

  const mediaGalleryEntries = useMemo(
    () => getMediaGalleryEntries(product, optionCodes, optionSelections),
    [product, optionCodes, optionSelections],
  );

  const handleAddProductToWishlist = useCallback(
    async ({ ...payload }) => {
      try {
        const {
          quantity,
          sku,
          parentSku,
          selected_options,
          entered_options,
          wishlistId,
        } = payload;

        if (wishlistId) {
          const d = await addProductToWishList(
            {
              wishlistId,
              wishlistItems: [
                {
                  quantity,
                  sku,
                  parent_sku: parentSku,
                  selected_options,
                  entered_options,
                },
              ],
            },
            setConfig(),
          );

          if (
            d.addProductsToWishlist &&
            !d?.addProductsToWishlist?.user_errors?.[0]
          ) {
            const wishlist = d?.addProductsToWishlist?.wishlist;
            wishlist && dispatch(reloadCustomer({ wishlist }));

            addToast('Product added to wishlist.', {
              appearance: 'success',
              autoDismiss: true,
            });
          } else {
            const userError =
              d?.addProductsToWishlist?.user_errors?.[0]?.message;
            addToast(
              userError ||
                'Unable to remove product from wishlist. Please try again.',
              {
                appearance: 'error',
                autoDismiss: true,
              },
            );
          }

          return d;
        }
      } catch (e) {
        console.log('Wishlist Error', e);
      }
    },
    [addProductToWishList, customer?.wishlist],
  );

  const handleAddToCart = useCallback(
    async (formValues) => {
      const { quantity, selected_options, entered_options, wishlistId } =
        formValues;
      const payload = {
        item: product,
        productType,
        quantity,
        parentSku: null,
      };

      if (isProductConfigurable(product)) {
        appendOptionsToPayload(payload, optionSelections, optionCodes);
      }

      if (isProductTypeSupported) {
        //creat cart id
        await handleEmptyCart();

        const cartId = getCartId();
        const variables: any = {
          cartId,
          parentSku: payload.parentSku,
          product: payload.item,
          quantity: payload.quantity,
          sku: payload.item.sku,
        };
        // Use the proper mutation for the type.
        if (productType === 'SimpleProduct') {
          try {
            if (wishlistId) {
              return await handleAddProductToWishlist({
                wishlistId,
                ...variables,
              });
            }

            return await addSimpleProductToCart(
              {
                ...variables,
              },
              setConfig(),
            );
          } catch {
            return {};
          }
        } else if (productType === 'ConfigurableProduct') {
          try {
            variables.selected_options = selected_options;
            variables.entered_options = entered_options;

            //TODO - TEMP solution - need to revamp for selected variant
            if (formValues?.item?.product?.sku) {
              variables.sku = formValues?.item?.product?.sku;
            }

            if (wishlistId) {
              return await handleAddProductToWishlist({
                wishlistId,
                ...variables,
              });
            }

            return await addConfigurableProductToCart(
              {
                ...variables,
              },
              setConfig(),
            );
          } catch {
            return {};
          }
        } else if (productType === 'BundleProduct') {
          const bundleProductPayload = {
            cartId,
            sku: payload.item.sku,
            quantity: payload.quantity,
            selected_options,
            entered_options,
          };

          if (wishlistId) {
            return await handleAddProductToWishlist({
              wishlistId,
              ...bundleProductPayload,
            });
          }

          try {
            const d = await addBundleProductToCart(
              {
                ...bundleProductPayload,
              },
              setConfig(),
            );

            if (
              Array.isArray(d?.addProductsToCart?.user_errors) &&
              d.addProductsToCart.user_errors.length
            ) {
              const userError = d.addProductsToCart.user_errors[0].message;
              addToast(userError || 'Something went. Please try again.', {
                appearance: 'error',
                autoDismiss: true,
              });
              return;
            }

            return d;
          } catch {
            return {};
          }
        }
      } else {
        console.error('Unsupported product type. Cannot add to cart.');
      }
    },
    [
      addConfigurableProductToCart,
      addSimpleProductToCart,
      isProductTypeSupported,
      optionCodes,
      optionSelections,
      product,
      productType,
    ],
  );

  const handleSelectionChange = useCallback(
    (optionId, selection) => {
      // We must create a new Map here so that React knows that the value
      // of optionSelections has changed.
      const nextOptionSelections = new Map({ ...(optionSelections || []) });
      nextOptionSelections.set(optionId, selection);
      setOptionSelections(nextOptionSelections);
    },
    [optionSelections],
  );

  const productPrice = useMemo(
    () => getConfigPrice(product, optionCodes, optionSelections),
    [product, optionCodes, optionSelections],
  );

  const productDetails = {
    description: product.description,
    name: product.name,
    price: productPrice,
    sku: product.sku,
  };

  const derivedErrorMessage = useMemo(
    () =>
      deriveErrorMessage([
        errorAddingSimpleProduct,
        errorAddingConfigurableProduct,
        errorBundleProduct,
        errorWishlist,
      ]),
    [
      errorAddingConfigurableProduct,
      errorAddingSimpleProduct,
      errorBundleProduct,
      errorWishlist,
    ],
  );

  return {
    // breadcrumbCategoryId,
    errorMessage: derivedErrorMessage,
    handleAddToCart,
    handleSelectionChange,
    isAddToCartDisabled:
      !isProductTypeSupported ||
      //TODO - commented because this does not let product add, need to reconfigured options
      // isMissingOptions ||
      isAddConfigurableLoading ||
      isBundleProductLoading ||
      isAddSimpleLoading,
    mediaGalleryEntries,
    productDetails,
    isConfigurable: isProductConfigurable(product),
  };
};

import addDays from 'date-fns/addDays';
import compareDesc from 'date-fns/compareDesc';

export const INCREMENT_DAYS_BY = 10;

/**
 * Calculates dispatch time based on selection of different product types
 * If the cart item added has matching fabric, then fabric dispatch_time gets added with the main product dispatch_time and date is displayed based on dispatched date days difference from current date of user session
 *
 * @param item CartItem which defines bundle product and configurable products having dispatch item
 * @returns dispatch_time (Number)
 */
export const scheduleDispatchDateForProduct = (item: any) => {
  let dispatch_time = Number(item.dispatch_time || item.product?.dispatch_time);
  var matchingFabric = [];

  if (Array.isArray(item.bundle_options)) {
    const dispatchTime = item.bundle_options.reduce((a: any, b: any) => {
      if (b.label == 'Matching Fabric') {
        let index = -1;
        const [matchingFabricProducts] = (
          item.product?.bundle_items || []
        ).filter((e: any) => {
          const uid = b?.values?.[0]?.uid;
          index =
            Array.isArray(e?.options) &&
            e.options.findIndex((x: any) => x.uid === uid);
          return e.title === 'Matching Fabric' && index > -1;
        });
        const time =
          matchingFabricProducts?.options?.[index]?.product?.dispatch_time;
        if (time) return time;
      }
      return a;
    }, null);

    if (dispatchTime) {
      dispatch_time += dispatchTime;
    }
  }

  return {
    dispatch_time,
    matchingFabric,
  };
};

const DISPATCH_DATE_INTERVAL = 44;

export const scheduleDispatchDate = (items: any, currentValue?: string) => {
  let selectedDate =
    typeof currentValue == 'string' ? new Date(currentValue) : new Date();
  let minDate = new Date();
  let maxDate = addDays(new Date(), DISPATCH_DATE_INTERVAL);

  const dispatch_time_list: any = [];

  for (var index in items) {
    const e = items[index];
    let dispatch_time = Number(e.product.dispatch_time);

    //filter matching fabric to add days based on requirement
    if (Array.isArray(e.bundle_options)) {
      const d = scheduleDispatchDateForProduct(e);
      dispatch_time_list.push(d.dispatch_time);
    } else {
      dispatch_time_list.push(dispatch_time);
    }
  }

  if (dispatch_time_list.length) {
    const today = 0;
    // const dayVariantBasedOnProductDispatchDates = Math.max(
    //   ...dispatch_time_list.filter((e) => !isNaN(e)),
    // );

    const dayVariantBasedOnProductDispatchDates = 0;
    const minDateDays = today + dayVariantBasedOnProductDispatchDates;
    // const maxDateDays = minDateDays + DISPATCH_DATE_INTERVAL + 9;
    const maxDateDays = DISPATCH_DATE_INTERVAL;

    minDate = addDays(new Date(), minDateDays + 10);
    maxDate = addDays(new Date(), maxDateDays);

    //compare and arrange the selected date to avoid past date selections
    const d =
      typeof currentValue == 'string' ? new Date(currentValue) : new Date();
    const dates = [d, minDate];
    dates.sort(compareDesc);
    if (dates[0]) {
      selectedDate = dates[0];
    }
  }

  return {
    minDate,
    maxDate,
    selectedDate,
  };
};

import { useCartContext } from '@/components/context/CartContext';
import { useProductDetail } from '@/hooks/ProductDetail/useProductDetail';
import {
  ADD_BUNDLE_MUTATION,
  ADD_CONFIGURABLE_MUTATION,
  ADD_PRODUCTS_TO_WISHLIST,
} from '@/services/graphql/queries/product/productFullDetail.gql';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

export const useAddToCart = (props = {}) => {
  const { product, variant }: any = props;

  const [
    {},
    { reloadCart, handleLoading, handleUpdateCartItem, handleGetCartItem },
  ]: any = useCartContext();
  const customer = useSelector((s: any) => ({ ...s.auth.customer }));
  const cartItems = useSelector((s: any) => ({ ...s.cart.items }));

  if (!product) return {};

  const { handleAddToCart, isAddToCartDisabled, isConfigurable } =
    useProductDetail({
      product,
      addProductToWishListMutation: ADD_PRODUCTS_TO_WISHLIST,
      addBundleProductToCartMutation: ADD_BUNDLE_MUTATION,
      addConfigurableProductToCartMutation: ADD_CONFIGURABLE_MUTATION,
    });

  const onAddToCart = async (payload: any) => {
    reloadCart({ updatingCartItem: payload.parentSku });
    handleLoading(true);
    const d = await handleAddToCart(payload);

    handleLoading(false);

    const keys = Object.keys(d || {});
    if (keys[0]) {
      const { cart, errors } = d[keys[0]] || {};
      if (errors) {
        reloadCart({ updatingCartItem: null });
        return d[keys[0]];
      } else if (cart) {
        reloadCart({ ...cart, updatingCartItem: null });
      }
    }
    return d;
  };

  const onAddToWishlist = async (payload: any) => {
    handleLoading(true);
    const d = await handleAddToCart(payload);
    handleLoading(false);

    const keys = Object.keys(d || {});
    if (keys[0]) {
      const { cart } = d[keys[0]] || {};
      if (cart) {
        reloadCart(cart);
      }
    }
    return d;
  };

  const handleAddProductToCart: any = useCallback(
    (e: any) => {
      try {
        if (isAddToCartDisabled) return null;

        if (e.cartItems && e.cartItems[0]) {
          const { wishlistId } = e;
          const { selected_options = {}, quantity = 1 } = e.cartItems[0];

          const selectedOptions = Object.keys(selected_options)
            .map(
              (e) =>
                selected_options[e] && {
                  uid: e,
                  value: String(selected_options[e]),
                },
            )
            .filter(Boolean);

          if (!selectedOptions?.length && !isConfigurable && !wishlistId)
            return null;

          const payload = {
            parentSku: product.sku,
            quantity,
            item: variant,
            selected_options: selectedOptions.map(({ uid }) => uid),
            entered_options: selectedOptions,
          };

          if (wishlistId) {
            return onAddToWishlist({ wishlistId, ...payload });
          }

          return onAddToCart(payload);
        }
        return null;
      } catch (e) {
        console.log('Add To Cart Error', e);
        return null;
      }
    },
    [product, variant],
  );

  const handleAddProductToWishlist = useCallback(
    async ({ ...payload }) => {
      try {
        const wishlistId = customer?.wishlist?.id;
        if (wishlistId)
          return handleAddProductToCart({ ...payload, wishlistId });
      } catch (e) {
        console.log('Error', e);
      }
    },
    [product, customer, customer?.wishlist],
  );

  const handleUpdateProductForCartItem = useCallback(
    async ({ e, uid }) => {
      try {
        if (isAddToCartDisabled || !uid) return;

        if (e.cartItems && e.cartItems[0]) {
          const { selected_options = {}, quantity } = e.cartItems[0];

          const selectedOptions = Object.keys(selected_options).map((e) => ({
            uid: e,
            value: String(selected_options[e]),
          }));

          if (!selectedOptions?.length && !isConfigurable) return;

          const cartItem = handleGetCartItem({ uid });

          const payload: any = {
            cart_item_uid: uid,
            quantity,
            selected_options: selectedOptions.map(({ uid }) => uid),
            entered_options: selectedOptions,
          };
          return handleUpdateCartItem([payload]);
        }
        return null;
      } catch (e) {}
    },
    [cartItems, product],
  );

  return {
    handleAddProductToCart,
    handleAddProductToWishlist,
    handleUpdateProductForCartItem,
  };
};

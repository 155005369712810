import Columns from '@/components/atoms/Columns';
import Heading from '@/components/atoms/Heading';
import cn from 'classnames';
import ButtonIconWrapper from '../molecules/ButtonIconWrapper';
import styles from './error.module.scss';
export default function Error() {
  return (
    <div
      className={cn(styles.root, 'is-fullhd')}
      style={{ backgroundImage: `url("/error/error.png")` }}
    >
      <Columns
        vCentered
        centered
        className={cn('is-mobile px-4', styles.error)}
      >
        <Columns.Column className='is-12-mobile is-offset-0-mobile is-6-tablet is-offset-6-tablet'>
          <div
            className={cn(
              'is-text-secondary has-text-weight-light',
              styles.heading,
            )}
          >
            404
          </div>
          <Heading as='h2' className={cn('is-dark-grey is-size-2')}>
            Oops!
          </Heading>
          <Heading as='h3' className='is-size-4 has-text-weight-bold'>
            sorry to say but it seems like the page doesn’t exist
          </Heading>
          <a href='/' style={{ maxWidth: 254 }}>
            <ButtonIconWrapper
              className={cn(
                'py-4 bg-primary has-text-white mx-auto',
                styles.button,
              )}
            >
              Go to homepage
            </ButtonIconWrapper>
          </a>
        </Columns.Column>
      </Columns>
    </div>
  );
}

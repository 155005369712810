import cn from 'classnames';
import styles from './min-cart.module.scss';
import CartItems from '@/components/checkout/CartItems';
import SvgIcon from '@/components/atoms/icon/SvgIcon';
import { useHistory } from 'react-router';
import { useCartContext } from '@/components/context/CartContext';

const MiniCartPopUp = ({ showMiniBag = false, toggleMiniBag }: any) => {
  const [{ isEmpty }]: any = useCartContext();
  const { push } = useHistory();

  return (
    <aside
      className={cn(
        styles.navmenu,
        'menu is-flex px-5 is-flex-direction-column is-dark-grey',
        isEmpty && 'is-justify-content-center',
        showMiniBag && styles.toggle,
      )}
    >
      <span className={styles.close}>
        <SvgIcon
          onClick={() => toggleMiniBag(false)}
          icon={'close'}
          size={'30'}
        />
      </span>
      {!isEmpty && <h5 className='is-size-5 has-text-weight-bold'>My Bag</h5>}
      <CartItems
        miniBag
        showMiniBag={showMiniBag}
        closePopups={() => {
          toggleMiniBag(false);
        }}
        onClick={() => {
          toggleMiniBag(false);
          push('/checkout/details/#mycart');
        }}
      />
    </aside>
  );
};

export default MiniCartPopUp;

import Element from '@/components/atoms/Element';
import Heading from '@/components/atoms/Heading';
import SvgIcon from '@/components/atoms/icon/SvgIcon';
import styles from './styles.module.scss';
import cn from 'classnames';

const IconsWrapper = ({
  className = null,
  children,
  leftIcon,
  rightIcon,
  leftIconCN = 'm-2',
  rightIconCN = 'm-2',
  as: As = 'div',
  ...props
}: any) => {
  return (
    <As className={cn(styles.root, className)} {...props}>
      <div className={leftIconCN}>{leftIcon}</div>
      <div className={'is-inline-flex has-text-centered'}>{children}</div>
      <div className={rightIconCN}>{rightIcon}</div>
    </As>
  );
};

export default IconsWrapper;

import { createContext, useContext, useMemo, useState } from 'react';

type SignupContextValue = { data?: any; onSetToken?: any; onResetToken?: any };

export const SignupContext = createContext<SignupContextValue>({});

export default function SignupProvider({ children }) {
  const [data, setData] = useState({ token: null, type: null });
  const onSetToken = (d: any) => {
    setData(d);
  };
  const onResetToken = () => {
    setData({ token: null, type: null });
  };
  const contextValue = useMemo(() => ({ data, onSetToken, onResetToken }), [
    data,
  ]);

  return (
    <SignupContext.Provider value={contextValue}>
      {children}
    </SignupContext.Provider>
  );
}

export const useSignupContext = () => useContext(SignupContext);

import Columns from '@/components/atoms/Columns';
import ButtonIconWrapper from '@/components/molecules/ButtonIconWrapper';
import { Link } from 'react-router-dom';
import SvgIcon from '../atoms/icon/SvgIcon';

const EmptyBag = ({ onClick = null }: any) => {
  return (
    <Columns centered className='py-6 my-auto'>
      <Columns.Column className='has-text-centered' size={'12'}>
        <div>
          <div style={{ maxWidth: 70, margin: 'auto' }}>
            <SvgIcon icon='empty-bag' size='100' />
          </div>
          <div className='is-dark-grey has-text-weight-bold is-size-5 pb-1 pb-5'>
            Your cart is empty, let's add some items.
          </div>
        </div>
        <Link to={'/women/fabriccategory.html'}>
          <ButtonIconWrapper
            fullwidth
            onClick={() => {
              onClick && onClick();
            }}
            titleClass='f-14'
          >
            add items to your cart
          </ButtonIconWrapper>
        </Link>
      </Columns.Column>
    </Columns>
  );
};

export default EmptyBag;

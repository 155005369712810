import { useAppContext } from '@/components/context/AppContext';
import subscribeNewsletterMutation from '@/services/graphql/fragments/subscribeToNewsLetter.graphql';
import { useCallback } from 'react';
import useGraphQLRequest from './useGraphQLRequest';
import { setConfig } from './customer/useSignIn';
import { useNeevToasts } from '@/components/context/toast';

const useNewsLetter = () => {
  const [subscribeNewsletter, { error, loading }] = useGraphQLRequest({
    query: subscribeNewsletterMutation,
  });
  const [{ handleLoader }] = useAppContext();
  const { addToast } = useNeevToasts();

  const handleSendNewsLetter = useCallback(
    async ({ email }) => {
      if (email) {
        try {
          handleLoader(true);

          const d = await subscribeNewsletter({ email }, setConfig());
          console.log('d', d);
          const isSubsribed =
            d?.subscribeEmailToNewsletter?.status === 'SUBSCRIBED';

          if (isSubsribed) {
            addToast('Your email is subscribed.', {
              appearance: 'success',
              autoDismiss: true,
            });
          } else {
            addToast('Your email is already subscribed.', {
              appearance: 'error',
              autoDismiss: true,
            });
          }

          handleLoader(false);
        } catch (e) {
          handleLoader(false);
          console.error('Something went wrong');
          addToast('Something went. Please try again.', {
            appearance: 'error',
            autoDismiss: true,
          });
          console.log(e);
        }
      }
    },
    [subscribeNewsletter],
  );

  return {
    handleSendNewsLetter,
    error,
    isLoading: loading,
  };
};

export default useNewsLetter;

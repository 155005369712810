import { createContext, useContext, useMemo, useReducer } from 'react';
import { createReducer, Reducer } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

// Need to change this
interface UserProviderState {
  isSignedIn?: boolean;
}
interface UserProviderApi {}
type UserContextValue = [UserProviderState, UserProviderApi];
const UserContext = createContext<UserContextValue>([{}, []]);
const UserProvider = (props) => {
  const { children } = props;
  const isSignedIn = useSelector((state: any) => state?.auth?.isSignedIn);
  const contextValue: UserContextValue = useMemo(() => {
    return [{ isSignedIn }, {}];
  }, [isSignedIn]);
  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};

export default UserProvider;
export const useUserContext = () => useContext(UserContext);

import gql from 'graphql-tag';
import { ProductListFragment } from '../product/productList.gql';

export const amount = `
  value
  currency
`;

export const CartItemsFragment = gql`
  fragment CartItemsFragment on Cart {
    items @include(if: $cartItems) {
      id
      uid
      product {
        id
        name
        url_key
        url_suffix
        thumbnail {
          url
        }
        stock_status
      }
      prices {
        price {
          ${amount}
        }
      }
      quantity
      ... on ConfigurableCartItem {
        configurable_options {
          id
          option_label
          value_id
          value_label
        }
      }
    }
  }
`;
export const CartPricesFragment = gql`
  fragment CartPricesFragment on Cart {
    prices @include(if: $cartPrices){
      base_grand_total {
        ${amount}
      }
      grand_total {
        ${amount}
      }
      subtotal_excluding_tax {
        ${amount}
      }
      subtotal_including_tax {
        ${amount}
      }
      subtotal_with_discount_excluding_tax {
        ${amount}
      }
      applied_taxes {
        amount {
          ${amount}
        }
        label
      }
      discounts {
        amount {
          ${amount}
        }
        label 
      }
    }
  }
`;
export const CartAddressFragment = gql`
  fragment CartAddressFragment on CartAddressInterface {
    city
    company
    country {
      code
      label
    }
    firstname
    lastname
    postcode
    region {
      code
      region_id
    }
    street
    telephone
  }
`;
export const CartBillingAddressFragment = gql`
  ${CartAddressFragment}

  fragment CartBillingAddressFragment on Cart {
    billing_address @include(if: $billing) {
      customer_address_id
      ...CartAddressFragment
    }
  }
`;

export const ShippingMethod = `
  amount {
    ${amount}
  }
  carrier_code
  carrier_title
  method_code
  method_title
`;

export const AvailableShippingMethods = gql`
  fragment AvailableShippingMethods on ShippingCartAddress{
    available_shipping_methods @include(if: $availableShippingMethods){
      ${ShippingMethod}
      available
      error_message
    }
  }
`;

export const AvailablePaymentMethods = gql`
  fragment AvailablePaymentMethods on Cart {
    available_payment_methods @include(if: $availablePaymentMethods) {
      code
      title
    }
  }
`;

export const ShippingMethodFragment = gql`
 fragment ShippingMethodFragment on ShippingCartAddress {
  selected_shipping_method @include(if: $shippingMethod) {
    ${ShippingMethod}
  }
}`;

export const CartShippingAddressFragment = gql`
  ${CartAddressFragment}
  ${AvailableShippingMethods}
  ${ShippingMethodFragment}

  fragment CartShippingAddressFragment on Cart {
    shipping_addresses @include(if: $shipping) {
      customer_address_id
      customer_notes
      ...ShippingMethodFragment
      ...CartAddressFragment
      ...AvailableShippingMethods
    }
  }
`;

export const CartPaymentMethodFragment = gql`
  fragment CartPaymentMethodFragment on Cart {
    selected_payment_method @include(if: $paymentMethod) {
      code
      purchase_order_number
      title
    }
  }
`;

export const AllFragments = gql`
  ${ProductListFragment}
  ${CartPricesFragment}
  ${CartPaymentMethodFragment}
  ${CartBillingAddressFragment}
  ${CartShippingAddressFragment}
  ${AvailablePaymentMethods}
`;

export const GET_CUSTOMER_CART = gql`
  query getCustomerCart(
    $cart_id: String!
    # $cartItems: Boolean!,
    $cartPrices: Boolean!
    $paymentMethod: Boolean!
    $billing: Boolean!
    $shipping: Boolean!
    $shippingMethod: Boolean!
    $availableShippingMethods: Boolean!
    $availablePaymentMethods: Boolean!
  ) {
    cart(cart_id: $cart_id) {
      id
      email
      total_quantity
      applied_coupons {
        code
      }
      special_requests {
        note
        gift_message
        dispatch_date
        send_invoice
      }

      applied_am_gift_cards {
        applied_balance {
          currency
          value
        }
        code
        current_balance {
          currency
          value
        }
        expiration_date
      }
      redeem_gift_card

      # ...CartItemsFragment
      ...ProductListFragment
      ...CartPricesFragment
      ...CartPaymentMethodFragment
      ...CartBillingAddressFragment
      ...CartShippingAddressFragment
      ...AvailablePaymentMethods
    }
  }

  ${AllFragments}
`;

export const ADD_TO_CART = gql`
  ${ProductListFragment}
  ${CartPricesFragment}

  mutation addProductsToCart(
    $cart_id: String!
    $sku: String!
    $quantity: Float!
    $cartItems: Boolean!
    $cartPrices: Boolean!
  ) {
    addProductsToCart(
      input: {
        cart_id: $cart_id
        cart_items: { data: { sku: $sku, quantity: $quantity } }
      }
    ) {
      cart {
        redeem_gift_card
        ...CartPricesFragment
        ...ProductListFragment
      }
      user_errors {
        code
        message
      }
    }
  }
`;

export const UPDATE_CART_ITEMS = gql`
  ${ProductListFragment}
  ${CartPricesFragment}
  ${CartShippingAddressFragment}
  ${CartPaymentMethodFragment}
  ${AvailablePaymentMethods}

  mutation updateCartItems(
    $cart_id: String!
    $cart_items: [CartItemUpdateInput]!
    $cartPrices: Boolean!
    $shipping: Boolean!
    $shippingMethod: Boolean!
    $availableShippingMethods: Boolean!
    $paymentMethod: Boolean!
    $availablePaymentMethods: Boolean!
  ) {
    updateCartItems(input: { cart_id: $cart_id, cart_items: $cart_items }) {
      cart {
        id
        ...CartPricesFragment
        ...ProductListFragment
        ...CartShippingAddressFragment
        ...CartPaymentMethodFragment
        ...AvailablePaymentMethods
      }
    }
  }
`;

export const REMOVE_CART_ITEM = gql`
  ${ProductListFragment}
  ${CartPricesFragment}
  ${CartShippingAddressFragment}

  mutation removeItemFromCart(
    $cart_id: String!
    $cart_item_id: ID!
    $cartPrices: Boolean!
    $shipping: Boolean!
    $shippingMethod: Boolean!
    $availableShippingMethods: Boolean!
  ) {
    removeItemFromCart(
      input: { cart_id: $cart_id, cart_item_uid: $cart_item_id }
    ) {
      cart {
        id
        ...CartPricesFragment
        ...ProductListFragment
        ...CartShippingAddressFragment
      }
    }
  }
`;

export const CREATE_EMPTY_CART = gql`
  mutation createEmptyCart {
    createEmptyCart
  }
`;

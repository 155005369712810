import { useEffect, useLayoutEffect, useState } from 'react';

function useWindowSize(fn: any, ...args) {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize]: any = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    let resizing = false,
      timeout;
    function handleResize() {
      // Set window width/height to state

      if (resizing) {
        return;
      }
      timeout = setTimeout(() => {
        resizing = false;
        if (fn) {
          fn({
            width: window.innerWidth,
            height: window.innerHeight,
          });
        } else {
          setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
          });
        }
      }, 100);
      resizing = true;
    }

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
      clearTimeout(timeout);
    };
  }, [fn, ...args]); // Empty array ensures that effect is only run on mount

  return windowSize;
}

export default useWindowSize;

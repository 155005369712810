import cn from 'classnames';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styles from './index.module.scss';

type Props = {
  open?: boolean;
  children: any;
  timeToHide?: number;
  type?: 'default' | 'danger';
  className?: string;
  ignoreTop?: boolean;
  onClose?: any;
  close?: boolean;
};

const Notification = ({
  open = false,
  children,
  timeToHide = 5000,
  type = 'default',
  className,
  ignoreTop,
  onClose = null,
  close = true,
}: Props) => {
  const [show, toggle] = useState(open);
  const [top, setTop] = useState(80);
  const {
    location: { pathname },
  } = useHistory();

  useEffect(() => {
    toggle(open);
    const headerTop = document.querySelector('.header-top');
    const isModalOpen = document.querySelector('.modal.is-active');
    const top = isModalOpen ? 0 : headerTop?.clientHeight || 0;

    setTop(top);

    const timer = setTimeout(() => {
      toggle(false);
      onClose && onClose();
    }, timeToHide);
    return () => clearTimeout(timer);
  }, [open]);

  return (
    <div
      style={{
        top: show ? (ignoreTop || pathname == '/' ? 0 : top) : -600,
      }}
      className={cn(
        'py-4 px-2 has-text-weight-bold f-14 has-text-centered',
        show && styles.show,
        styles.notification,
        className,
        {
          [styles[`is-${type}`]]: !!type,
        },
      )}
    >
      {children}
      {/* Commented temporary */}
      {/* {close && (
        <button
          onClick={() => {
            toggle(false);
            onClose && onClose();
          }}
          className={cn('delete', styles.delete)}
        ></button>
      )} */}
    </div>
  );
};
export default Notification;

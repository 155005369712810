// import { useProviderCart } from '@/hooks/checkout/useCart';
import { useCart } from '@/hooks/checkout/useCart';
import { scheduleDispatchDate } from '@/services/scheduleDispatchDate';
import { loading, reload, reset } from '@/store/reducers/cart.reducer';
import { createContext, useCallback, useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

interface CartContextState {
  // cartId?: string;
  isCartLoading?: boolean;
}
interface CartContextApi {}

type CartContextValue = [CartContextState, CartContextApi];

export const CartContext = createContext<CartContextValue>([{}, {}]);

const CartProvider = ({ children }) => {
  const useCartProps = useCart({});
  const cart = useSelector((s: any) => ({ ...s.cart }));
  const cartItems = useSelector((s: any) => ({ ...s.cart.items }));

  const dispatch = useDispatch();
  const handleLoading = (flag = false) => dispatch(loading({ loading: flag }));
  const setDiscount = (v: number) => dispatch(reload({ discount: v }));
  const reloadCart = (v: any) => dispatch(reload({ ...v }));
  const resetCart = () => dispatch(reset());

  //GET INDIVIDUAL CART ITEM DETAILS
  const handleGetCartItem = useCallback(
    ({ uid }) => {
      if (uid) {
        const cartItem = cart.items?.filter((e: any) => e.uid === uid)[0];
        return cartItem;
      }

      return null;
    },
    [cartItems],
  );

  const dispatchDateConfig = useMemo(() => {
    const config = scheduleDispatchDate(
      cart.items,
      cart.special_requests?.dispatch_date,
    );
    return config;
  }, [cart.items?.length, cart.items, cart.special_requests?.dispatch_date]);

  const cartDiscount = useMemo(() => {
    if (Array.isArray(cart.applied_am_gift_cards)) {
      return cart.applied_am_gift_cards.reduce((a: number, e: any) => {
        return a + +e.current_balance.value;
      }, 0);
    }

    return 0;
  }, [cart.applied_am_gift_cards]);

  const contextValue: CartContextValue = useMemo(() => {
    return [
      {
        dispatchDateConfig,
        isCartLoading: !!cart.loading,
        isEmpty: !cart?.items?.length,
        cartItems: cart?.items || [],
        totalCartItems: cart?.items?.length || 0,
        ...(cart.prices && {
          cartTotal: cart?.prices?.grand_total?.value,
          cartSubTotal: cart?.prices?.subtotal_excluding_tax?.value,
          cartDiscount,
          cartDiscountTotal:
            cart?.prices?.grand_total?.value - (cart.discount || 0),
          cartPrices: cart?.prices || [],
        }),
        selectedPaymentMethod: cart?.selected_payment_method?.code,
        shipping: cart?.shipping_addresses?.[0],
        billing:  cart?.billing_address,
        ...cart,
      },
      {
        ...useCartProps,
        handleLoading,
        setDiscount,
        reloadCart,
        resetCart,
        handleGetCartItem,
      },
    ];
  }, [cart, dispatchDateConfig, cart?.id, cartItems]);

  return (
    <CartContext.Provider value={contextValue}>{children}</CartContext.Provider>
  );
};

export const useCartContext = () => useContext(CartContext);
export default CartProvider;

import gql from 'graphql-tag';
import MediaGallery from '@/services/graphql/fragments/product/MediaGallery.gql';
import ProductPrice from '@/services/graphql/fragments/product/ProductPrice.gql';

export const ProductDetailAttributes = `
  image {
    label
    url
  }

  thumbnail {
    label
    url
  }

  price_range {
    maximum_price ${ProductPrice()}
    minimum_price ${ProductPrice()}
  }

  categories {
    id
    name
    url_key
    url_path
    url_suffix

    breadcrumbs {
      category_id
      category_name
      category_url_path
    }
  }

  description {
    html
  }

  id
  media_gallery ${MediaGallery}
  name
  sku
  url_key
  url_suffix
  stock_status
`;

import { Component, createContext, createElement, PureComponent } from 'react';

import {
  Route,
  Switch,
  Redirect,
  withRouter,
  matchPath,
  RouteProps,
  RouteChildrenProps,
  StaticContext,
  RouteComponentProps,
} from 'react-router';

import ErrorBoundary from '@/components/ErrorBoundary';
import Layout from '@/components/Layout';
import AppContextProvider from '@/components/context/AppContext';
import loadable from '@loadable/component';
import UserProvider from './context/UserContext';
import Symbols from '@/components/symbols/Symbols';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import { faLock } from '@fortawesome/free-solid-svg-icons/faLock';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons/faEyeSlash';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { faFile } from '@fortawesome/free-solid-svg-icons/faFile';

import CartProvider from './context/CartContext';
import EventEmitter from 'eventemitter3';

import '../../styles/style.scss';
import NeevToastProvider from '@/components/context/toast';
// import HomePageProvider from '@/hooks/useHome';

const ErrorLoadable = loadable(() => import('@/components/ErrorPage'));

library.add(
  faEnvelope,
  faCheck,
  faExclamationTriangle,
  faLock,
  faEyeSlash,
  faEye,
  faChevronLeft,
  faChevronRight,
  faFile,
);

interface AppState {
  data: any;
  previousLocation: any;
  currentLocation: any;
  isLoading: boolean;
}
interface AppProps extends RouteComponentProps {
  initialData: any;
  routes: IRoute[];
  staticContext: StaticContext;
  appData: { menu: any; currencyList?: any; storeConfig: any };
  eventEmitter?: EventEmitter;
}

class App extends PureComponent<AppProps, AppState> {
  static contextType?: React.Context<any> = createContext<any>({});
  context: any;
  prefetcherCache = {};
  ignoreLastFetch = false;
  state: AppState;
  constructor(props: AppProps) {
    super(props);
    this.state = {
      data: props.initialData,
      previousLocation: null,
      currentLocation: props.location,
      isLoading: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const currentLocation = props.location;
    const previousLocation = state.currentLocation;

    const navigated = currentLocation !== previousLocation;
    if (navigated) {
      return {
        previousLocation: state.previousLocation || previousLocation,
        currentLocation,
      };
    }
    return null;
  }
  componentDidUpdate(_prevProps, prevState) {
    const navigated = prevState.currentLocation !== this.state.currentLocation;
    if (navigated) {
      if (typeof window !== undefined) {
        window.scrollTo(0, 0);
      }
      const {
        location,
        history,
        routes,
        // data,
        // we don't want to pass these
        // to loadInitialProps()
        match,
        staticContext,
        children,
        ...rest
      } = this.props;
      const promises: any[] = [];
      routes.map((route: RouteProps) => {
        const match = matchPath(location.pathname, {
          ...route,
          path: route.path || '*',
        });

        const component: any = route.component;
        if (match && component) {
          promises.push(
            component.load ? component.load() : Promise.resolve(component),
          );
        }

        return !!match;
      });

      Promise.all(promises)
        .then(([d]) => {
          if (d) {
            if (!!d.isServerSideRendered) return this.fetchData();
            return Promise.resolve(null);
          } else {
            return this.fetchData();
          }
        })
        .then((data) => {
          if (this.state.currentLocation !== location) return;
          if (
            (prevState.previousLocation &&
              prevState.previousLocation.pathname) !== location.pathname
          ) {
            // Add query params handle scroll to top
            window.scrollTo(0, 0);
          }
          // if (CorePages[data.Page] && CorePages[data.Page].load) {
          //   return CorePages[data.Page].load().then((d) => {
          //     this.setState({ previousLocation: null, data });
          //   });
          // }
          this.setState({ previousLocation: null, data });
        })
        .catch((d) => {
          console.log('error', d);
        });
    }
  }
  componentWillUnmount() {
    this.ignoreLastFetch = true;
  }
  fetchData = () => {
    if (!this.ignoreLastFetch) {
      const { pathname, search } = this.props.location;
      const dataUrl = `/_data${
        pathname == '/' ? '/index' : pathname
      }.json${search}`;

      this.setState({
        isLoading: true,
      });

      return fetch(dataUrl)
        .then((d) => {
          if (d.ok) {
            return d.json();
          }
          return d.text();
        })
        .catch((error) => {
          console.log('this.ignoreLastFetch error', error);
          this.setState((state) => ({
            data: { error },
            isLoading: false,
          }));
        })
        .finally(() =>
          this.setState({
            isLoading: false,
          }),
        );
    }
    return Promise.resolve(null);
  };

  handleLoader = (isLoading: boolean) => {
    this.setState({
      isLoading,
    });
  };

  prefetch = () => {};
  render() {
    const { previousLocation, data, isLoading } = this.state;
    const { location: currentLocation } = this.props;
    const initialData =
      this.prefetcherCache[currentLocation.pathname] || data || {};
    const location = previousLocation || currentLocation;
    return (
      <AppContextProvider
        {...this.props.appData}
        initialData={initialData.props}
        isAppLoading={this.state.isLoading}
        handleLoader={this.handleLoader}
        eventEmitter={this.props.eventEmitter}
      >
        {/* <HomePageProvider> */}
        <UserProvider>
          <NeevToastProvider>
            <CartProvider>
              <ErrorBoundary>
                <Layout isLoading={isLoading}>
                  <Switch location={location}>
                    {initialData?.props?.statusCode === 404 && (
                      <Route
                        component={Component404}
                        path={location.pathname}
                      />
                    )}

                    {initialData &&
                      initialData.redirectTo &&
                      initialData.redirectTo && (
                        <Redirect to={initialData.redirectTo} />
                      )}

                    {this.props.routes.map((r, i) => {
                      let path = r.path;
                      if (r.path == initialData.Page) path = location.pathname;
                      return (
                        <Route
                          key={`route--${i}`}
                          path={path}
                          exact={r.exact}
                          render={(props) =>
                            createElement(r.component, {
                              ...props,
                              ...initialData.props,
                              prefetch: this.prefetch,
                            })
                          }
                        />
                      );
                    })}
                    <Route path='*' component={Component404} />
                  </Switch>
                </Layout>
              </ErrorBoundary>
              <Symbols />
            </CartProvider>
          </NeevToastProvider>
        </UserProvider>
        {/* </HomePageProvider> */}
      </AppContextProvider>
    );
  }
}

const Component404 = (props) => {
  const { staticContext } = props;
  if (staticContext) staticContext.status = 404;
  return <ErrorLoadable />;
};

export default withRouter(App);

import ProductPrice from '@/services/graphql/fragments/product/ProductPrice.gql';

export const configurableProduct = `
fragment configoption on ConfigurableProduct{
	... variants
  configurable_options{
    attribute_code
    attribute_uid
    label
    position
    uid
    use_default
    values {
      default_label
      label
      uid
      swatch_data {
        value
      }
    }
  }
}
fragment variants on ConfigurableProduct{
  variants{
    attributes {
      code
      label
      uid
#      value_index
    }
    product {
      uid
      thumbnail {
        url
      }
      media_gallery {
        url
        label
        position
        landscape

        disabled
        ... on ProductVideo {
          video_content {
            media_type
            video_provider
            video_url
            video_title
            video_description
            video_metadata
          }
        }
      }
      sku
      name
      url_key
      image {
        url
      }
      description {
        html
      }
      
      price_range {
        minimum_price ${ProductPrice()}
      }
    }
  }
}

fragment CustomOption on CustomizableProductInterface{
  options{
    required
    sort_order
    title
    uid  
    ... on CustomizableRadioOption {
      title
      value {
        sku
        title
        uid
        price
      }
    }
  }
}

`;

export const BundleProduct = `
  fragment BP on BundleProduct{
    dynamic_sku
    dynamic_price
    dynamic_weight
    price_view
    ship_bundle_items
    bundle_items : items{
      uid
      title
      #required
      sku
      position
      type
      options {
        is_default
        can_change_quantity
        label
        position
        price
        price_type
        quantity
        uid
        product {
          # ... on CustomizableProductInterface {
          #  ...CustomOption
          # }
          
          dispatch_time

          fabric
          dimensions
          work
          care
          core_meta:dynamicAttributes(fields: ["fabric","work"])
          
          #best_seller
          #canonical_url
          #gift_message_available
          #only_x_left_in_stock
          qty_range
          special_price
          
          thumbnail {
            url
          }
          media_gallery {
            url
            landscape
            label
            position
            disabled
            ... on ProductVideo {
              video_content {
                media_type
                video_provider
                video_url
                video_title
                video_description
                video_metadata
              }
            }
          }
          sku
          name
          url_key
          #image {
          #  url
          #}
          description {
            html
          }
          stock_status
          price_range {
            minimum_price ${ProductPrice()}
            maximum_price ${ProductPrice()}
          }
        }
      }
      #position
      #required
      #sku
      #title
      #type
      #uid
    }
  }
`;

import Modal from '@/components/atoms/Modal';
import loadable from '@loadable/component';

const ModalContent = loadable(() => import('./ModalContent'));

export default function SignupModal(props) {
  return (
    <Modal show={props.show} onClose={props.onClose}>
      <ModalContent {...props} />
    </Modal>
  );
}

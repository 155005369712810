import styles from './index.module.scss';
import Modal from '@/components/atoms/Modal';
import { useEffect, useState } from 'react';
import loadable from '@loadable/component';
const ModalContent = loadable(() => import('./ModalContent'));

export default function ForgotPasswordModal(props) {
  return (
    <Modal show={props.show} onClose={props.onClose}>
      <ModalContent />
    </Modal>
  );
}

// export default function () {
//   'use strict';
//   if (process.env.__BROWSER__ == 'true') {
//     return window.localStorage;
//   }
//   function LocalStorage() {}
//   LocalStorage.prototype.getItem = function (key) {
//     if (this.hasOwnProperty(key)) {
//       return String(this[key]);
//     }
//     return null;
//   };

//   LocalStorage.prototype.setItem = function (key, val) {
//     this[key] = String(val);
//   };

//   LocalStorage.prototype.removeItem = function (key) {
//     delete this[key];
//   };

//   LocalStorage.prototype.clear = function () {
//     var self = this;
//     Object.keys(self).forEach(function (key) {
//       self[key] = undefined;
//       delete self[key];
//     });
//   };

//   LocalStorage.prototype.key = function (i) {
//     i = i || 0;
//     return Object.keys(this)[i];
//   };

//   LocalStorage.prototype.__defineGetter__('length', function () {
//     return Object.keys(this).length;
//   });

//   return new LocalStorage();
//   // if (global.window.localStorage) {
//   //   module.exports = localStorage;
//   // } else {
//   //   module.exports = new LocalStorage();
//   // }
// }

export default function () {
  'use strict';

  function LocalStorage() {}
  LocalStorage.prototype.getItem = function (key) {
    if (this.hasOwnProperty(key)) {
      return String(this[key]);
    }
    return null;
  };

  LocalStorage.prototype.setItem = function (key, val) {
    this[key] = String(val);
  };

  LocalStorage.prototype.removeItem = function (key) {
    delete this[key];
  };

  LocalStorage.prototype.clear = function () {
    var self = this;
    Object.keys(self).forEach(function (key) {
      self[key] = undefined;
      delete self[key];
    });
  };

  LocalStorage.prototype.key = function (i) {
    i = i || 0;
    return Object.keys(this)[i];
  };

  LocalStorage.prototype.__defineGetter__('length', function () {
    return Object.keys(this).length;
  });

  // return new LocalStorage();
  if (typeof window != 'undefined') {
    return global.window.localStorage;
  } else {
    return new LocalStorage();
  }
}

import { deriveErrorMessage } from '@/services/helpers/deriveErrorMessage';
import { useCallback, useEffect, useMemo } from 'react';
import useGraphQLRequest from '../useGraphQLRequest';

import GENERATE_TOKEN from '@/services/graphql/fragments/customer/generateToken.graphql';
import CHANGE_PASSWORD from '@/services/graphql/fragments/customer/changePassword.graphql';
import REVOKE_CUSTOMER_TOKEN from '@/services/graphql/fragments/customer/revokeCustomerToken.graphql';
import MERGE_CARTS from '@/services/graphql/fragments/cart/mergeCarts.graphql';
// import GET_CUSTOMER from '@/services/graphql/fragments/customer/customer.graphql';
import {
  GET_CUSTOMER,
  RESET_PASSWORD_MUTATION,
} from '@/services/graphql/fragments/customer/customer.gql';

import { GET_CUSTOMER_CART } from '@/services/graphql/fragments/cart/cart.gql';

import { useDispatch, batch } from 'react-redux';
import { loading, login, logout } from '@/store/reducers/auth.reducer';
import { reload, reset } from '@/store/reducers/cart.reducer';

import { useHistory } from 'react-router';
import PersistanceStorage from '@/services/storage';
import { getToken, removeToken, setToken } from '@/services/storage/user';
import {
  getCartId,
  getGuestCartId,
  setCartId,
  removeCartId,
} from '@/services/storage/cart';
import { useNeevToasts } from '@/components/context/toast';
import { useAppContext } from '@/components/context/AppContext';
import { removeBillingAsShipping } from '@/services/storage/shipping';

export const setConfig = () => {
  const token = getToken();
  return {
    headers: {
      ...(token && { authorization: `Bearer ${getToken()}` }),
    },
  };
};

export const useSignIn = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { addToast } = useNeevToasts();
  const [, { openLoginModal }] = useAppContext();

  //grapqhl
  const mergeCartsMutation: any = {
    method: 'post',
    query: MERGE_CARTS,
  };

  const generateTokenMutation: any = {
    method: 'post',
    query: GENERATE_TOKEN,
  };
  const changePasswordMutation: any = {
    method: 'post',
    query: CHANGE_PASSWORD,
  };
  const resetPasswordMutation: any = {
    method: 'post',
    query: RESET_PASSWORD_MUTATION,
  };
  const signOutMutation: any = {
    method: 'post',
    query: REVOKE_CUSTOMER_TOKEN,
  };
  const getCustomerQuery: any = {
    method: 'post',
    query: GET_CUSTOMER,
  };
  const getCustomerCartQuery: any = {
    method: 'post',
    query: GET_CUSTOMER_CART,
  };

  //FETCH CUSTOMER
  const [
    getCustomer,
    {
      error: errorFetchingCustomer,
      loading: isCustomerLoading,
      data: customer,
    },
  ] = useGraphQLRequest(getCustomerQuery);
  //FETCH CUSTOMER CART
  const [
    getCustomerCart,
    {
      error: errorFetchingCustomerCart,
      loading: isCustomerCartLoading,
      data: customerCart,
    },
  ] = useGraphQLRequest(getCustomerCartQuery);

  const handleCustomer = useCallback(
    async ({ withCart = false, onlyCart = false, onlyCustomer = true }) => {
      try {
        const token = getToken();
        if (token) {
          const promiseArray: any = [];

          //fetch customer details with cart flag
          if (withCart || onlyCustomer) {
            promiseArray.push(getCustomer.bind(null, {}, setConfig()));
          }
          //fetch only cart details or with customer details
          // if (withCart || onlyCart) {
          //   promiseArray.push(getCustomerCart.bind(null, {}, setConfig()));
          // }

          dispatch(loading({
            loading: true
          }));

          const [customer] = await Promise.all([
            ...promiseArray.map((p) => p()),
          ]);

          //set customer loggedin cart id
          
          if (customer?.customerCart?.id) {
            setCartId(customer?.customerCart.id);
            dispatch(reload({ ...customer?.customerCart }));
          }

          dispatch(login({ customer: { ...customer?.customer } }));

          return {
            ...customer,
          };
        }
        return {};
      } catch (e) {
        console.log('Error', e);
        return {};
      }
    },
    [getCustomer || getCustomerCart],
  );

  //MERGE CARTS
  const [mergeCarts, { error: errorMergingCarts, loading: isCartBusy }] =
    useGraphQLRequest(mergeCartsMutation);

  const handleMergeCart = useCallback(
    async ({ source_cart_id, destination_cart_id }) => {
      try {
        return await mergeCarts(
          {
            source_cart_id,
            destination_cart_id,
          },
          setConfig(),
        );
      } catch (e) {
        console.log('Error', e);
        return;
      }
    },
    [mergeCarts],
  );

  // GENERATE TOKEN FOR CUSTOMER TO MAINTAIN SESSION
  const [
    generateToken,
    { error: errorInLoginIn, loading: loadingToken, data: accessToken = null },
  ] = useGraphQLRequest(generateTokenMutation);

  const handleSignIn = useCallback(
    async (payload) => {
      try {
        generateToken({
          ...payload,
        }).then(async (d) => {
          const token = d?.generateCustomerToken?.token;
          setToken(token);

          if (token) {
            //get customer with cart
            const { customerCart = {}, customer } = await handleCustomer({});

            //get guest cart id
            const guestCartId = getGuestCartId();

            //merge guest with customers cart after log in
            if (customerCart && customerCart.id) {
              if (!!guestCartId) {
                await handleMergeCart({
                  source_cart_id: guestCartId,
                  destination_cart_id: customerCart.id,
                });
              }

              //remove guest cart id
              removeCartId(true);
              //set customer loggedin cart id
              setCartId(customerCart.id);

              dispatch(reload({ ...customerCart }));
            }

            dispatch(login({ customer: { ...customer?.customer } }));
            history.push('/');
          }
        });
      } catch (e) {
        console.log('Error', e);
        return;
      }
    },
    [generateToken],
  );

  // REVOKE TOKEN FOR CUSTOMER ON LOGOUT
  const [
    revokeCustomerToken,
    { error: errorInRevokingCustomerToken, loading: isRevokingToken },
  ] = useGraphQLRequest(signOutMutation);

  const handleLogout = useCallback(async () => {
    try {
      if (getToken()) {
        const d = await revokeCustomerToken({}, setConfig());
        if (d?.revokeCustomerToken?.result) {
          removeCartId();
          removeToken();
          removeBillingAsShipping();
          dispatch(logout());
          dispatch(reset());
          history.push('/');
        }
      } else {
        dispatch(logout());
        history.push('/');
      }
    } catch {
      return;
    }
  }, [revokeCustomerToken]);

  // CHANGE PASSWORD
  const [
    changePassword,
    { error: errorInChangePassword, loading: requestingChangePassword },
  ] = useGraphQLRequest(changePasswordMutation);

  const handleChangePassword = useCallback(
    async (payload) => {
      try {
        const res = await changePassword(
          {
            ...payload,
          },
          setConfig(),
        );
        return res && res?.changeCustomerPassword?.email;
      } catch {
        return;
      }
    },
    [changePassword],
  );

  // RESET PASSWORD WITH RESET TOKEN
  const [
    resetPassword,
    { error: errorInResetPassword, loading: isLoadingResetPassword },
  ] = useGraphQLRequest(resetPasswordMutation);

  const handleResetPasswordWithToken = useCallback(
    async (payload) => {
      try {
        const res = await resetPassword(
          {
            ...payload,
          },
          setConfig(),
        );

        console.log('Error.error', res);

        if (res?.resetPassword) {
          return res?.resetPassword;
        }

        throw res?.resetPassword;
      } catch (e) {
        console.log('Error', e);
        return e;
      }
    },
    [resetPassword],
  );

  //COMBINE ERROR MESSAGES ON CHANGE
  const derivedErrorMessage = useMemo(
    () =>
      deriveErrorMessage([
        errorInLoginIn,
        errorInChangePassword,
        errorInRevokingCustomerToken,
        errorMergingCarts,
        errorFetchingCustomer,
        errorFetchingCustomerCart,
        errorInResetPassword,
      ]),
    [
      errorInLoginIn,
      errorInChangePassword,
      errorInRevokingCustomerToken,
      errorMergingCarts,
      errorFetchingCustomer,
      errorFetchingCustomerCart,
      errorInResetPassword,
    ],
  );

  //COMBINE LOADING STATUSES
  const isLoading = useMemo(
    () =>
      loadingToken ||
      requestingChangePassword ||
      isRevokingToken ||
      isCustomerCartLoading ||
      isCustomerLoading ||
      isLoadingResetPassword ||
      isCartBusy,
    [
      isLoadingResetPassword,
      requestingChangePassword,
      isCustomerCartLoading,
      isCustomerLoading,
      isRevokingToken,
      isCartBusy,
    ],
  );

  const handleCheckIfTokenIsExpired = useCallback(() => {
    const checkIfTokenIsExpire =
      derivedErrorMessage.indexOf(`The current customer isn't authorized`) > -1;

    if (checkIfTokenIsExpire) {
      removeCartId();
      removeToken();
      batch(() => {
        dispatch(logout());
        dispatch(reset());
      });
      openLoginModal();
    }
  }, [derivedErrorMessage]);

  useEffect(() => {
    if (derivedErrorMessage) {
      handleCheckIfTokenIsExpired();

      addToast(derivedErrorMessage, {
        statusCode: 500,
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [derivedErrorMessage]);

  const isError = useMemo(() => {
    if (errorInChangePassword) {
      return (
        errorInChangePassword?.response?.errors[0]?.message ||
        'Something went wrong, Please try again later'
      );
    } else {
      return;
    }
  }, [errorInChangePassword]);

  //redirections
  const toForgotPassword = ({ cb, override = false }) => {
    cb && cb();
    !override && history.push('/forgot-password');
  };

  const toSignUp = ({ cb, override = false }) => {
    cb && cb();
    !override && history.push('/signup');
  };

  return {
    customer: {
      ...customer,
      customerCart,
    },
    errorMessage: derivedErrorMessage,
    isError,
    isLoading,
    errorInLoginIn,
    isSignedIn: () => !!getToken(),
    handleCustomer,
    handleMergeCart,
    handleLogout,
    handleSignIn,
    handleChangePassword,
    handleResetPasswordWithToken,
    toSignUp,
    toForgotPassword,
    handleCheckIfTokenIsExpired,
  };
};

import Modal from '@/components/atoms/Modal';
import { useAppContext } from '@/components/context/AppContext';
import { getCookieConsent } from '@/services/storage/cookie-consent';
import loadable from '@loadable/component';
import { useEffect } from 'react';
import styles from '../index.module.scss';

const ModalContent = loadable(() => import('./ModalContent'));

export default function CookiePolicyModal(props: any) {
  const [, { openCookiePolicyModal }] = useAppContext();

  useEffect(() => {
    if (!getCookieConsent()) {
      setTimeout(() => {
        openCookiePolicyModal();
      }, 1000);
    }
  }, []);

  return (
    <Modal
      className={styles['modal-container']}
      noPortal
      show={props.show}
      onClose={props.onClose}
      showClose={false}
    >
      <ModalContent {...props} />
    </Modal>
  );
}

import { useMemo, useCallback } from 'react';
import SvgIcon from '../atoms/icon/SvgIcon';
import cn from 'classnames';
import { useAddToCart } from '@/hooks/cart/useAddToCart';
import { useCustomer } from '@/hooks/customer/useCustomer';
import { useSelector } from 'react-redux';
import { useSignIn } from '@/hooks/customer/useSignIn';
import { useAppContext } from '@/components/context/AppContext';

export const SaveUnSave = ({
  className = '',
  getPayload,
  productId,
  iconVersion = 1,
}: any) => {
  const customer = useSelector((state: any) => ({ ...state.auth.customer }));

  const { handleAddProductToWishlist }: any = useAddToCart({
    ...(getPayload && getPayload()),
  });
  const [
    ,
    { handleCheckIfProductExistsInWishlist, handleRemoveProductFromWishlist },
  ]: any = useCustomer();
  const [, { openLoginModal }] = useAppContext();
  const { isSignedIn } = useSignIn();

  //TODO - check why productId gets undefined
  const [ifExistsInList]: any = useMemo(() => {
    return handleCheckIfProductExistsInWishlist({
      id: productId,
      wishlist: customer?.wishlist || {},
    });
  }, [productId, customer?.wishlist]);

  //TODO - Very bad hack - this is done due to rerender issue. Need to reconfigure.
  const onClickIcon = useCallback(
    ({ wishlist }) => {
      //check if user is not logged in
      if (!isSignedIn()) {
        openLoginModal();
        return;
      }

      const [ifExistsInList] = handleCheckIfProductExistsInWishlist({
        id: productId,
        wishlist,
      });

      if (!ifExistsInList && getPayload) {
        const { payload } = getPayload();
        handleAddProductToWishlist(payload);
      } else {
        handleRemoveProductFromWishlist({ id: productId }, {});
      }
    },
    [productId, customer?.wishlist],
  );

  return (
    <SvgIcon
      className={cn(className)}
      onClick={(e) => {
        e.preventDefault();
        e && e.stopPropagation();
        onClickIcon({ wishlist: customer?.wishlist });
      }}
      icon={
        ifExistsInList
          ? iconVersion == 2
            ? 'like-active'
            : 'heart-active'
          : iconVersion === 2
          ? 'like-inactive'
          : 'heart'
      }
      size={iconVersion == 2 ? '42' : '30'}
    />
  );
};

export const Delete = ({ className = '', onClick = null }: any) => {
  return (
    <SvgIcon
      onClick={onClick}
      className={cn(className)}
      icon={'delete'}
      size={'25'}
    />
  );
};

export const Share = ({ className = '', ...props }) => {
  return (
    <SvgIcon
      className={cn('mx-3', className)}
      icon={'share'}
      size={'22'}
      {...props}
      onClick={props.share.onClick}
    />
  );
};

export const SaveShare = ({
  reverse = false,
  share = {},
  className = '',
  save = {},
}) => {
  return (
    <div
      className={cn(
        'is-flex',
        {
          ['is-flex-direction-row-reverse']: reverse,
        },
        className,
      )}
    >
      <SaveUnSave className={'mx-3'} {...save} />
      <Share {...share} />
    </div>
  );
};

import {
  BundleProduct,
  configurableProduct,
} from '@/routes-data/productConfig';
import { gql } from 'graphql-request';
import ConfigurableProductFragment from '../../queries/Product.gql/ConfigurableProductFragment.gql';

export const BundleCartItem = gql`
  fragment BundleCartItemFragment on BundleCartItem {
    bundle_options {
      uid
      label
      type
      id
      values {
        id
        label
        uid
        quantity
        price
      }
    }
    # bundleCustomizableOptions: customizable_options {
    #   customizable_option_uid
    #   id
    #   is_required
    #   label
    #   sort_order
    #   type
    #   values {
    #     customizable_option_value_uid
    #     label
    #     price {
    #       type
    #       value
    #       units
    #     }
    #   }
    # }
    # gift_message {
    #   from
    #   to
    #   message
    # }
    product {
      uid
      name
      url_key
      url_suffix
      thumbnail {
        url
      }
      stock_status
    }
  }
`;

export const ConfigurableCartItem = gql`
  ${ConfigurableProductFragment}
  fragment ConfigurableCartItemFragment on ConfigurableCartItem {
    configurable_options {
      configurable_product_option_uid
      configurable_product_option_value_uid
      option_label
      value_id
      id
      value_label
    }

    product {
      # uid
      # name
      # url_key
      # url_suffix
      # thumbnail {
      #   url
      # }
      # stock_status
      ...ConfigurableProductFragment
    }
  }
`;

export const AmGiftCardProduct = gql`
  fragment AmGiftCardProductFragment on AmGiftCardProduct {
    am_giftcard_type
    am_giftcard_fee_enable
    am_open_amount_max
    am_allow_open_amount
    am_giftcard_lifetime
    name
    am_giftcard_fee_value
    gift_message_available
    am_giftcard_prices {
      attribute_id
      price_id
      value {
        value
        currency
      }
    }
  }
`;

export const ProductListFragment = gql`
  ${BundleCartItem}
  ${ConfigurableCartItem}
  ${configurableProduct}
  ${BundleProduct}
  ${AmGiftCardProduct}

  fragment ProductListFragment on Cart {
    items {
      id
      uid
      product {
        __typename
        dispatch_time
        id
        sku
        name
        url_key
        url_suffix
        thumbnail {
          url
        }
        stock_status

        ... on ConfigurableProduct {
          ...configoption
        }

        ... on BundleProduct {
          ...BP
        }

        ... on CustomizableProductInterface {
          ...CustomOption
        }

        ... on AmGiftCardProduct {
          ...AmGiftCardProductFragment
        }
      }
      prices {
        price {
          currency
          value
        }
      }
      quantity
      ... on ConfigurableCartItem {
        ...ConfigurableCartItemFragment
      }

      ... on BundleCartItem {
        ...BundleCartItemFragment
      }

      ... on AmGiftCardCartItem {
        am_giftcard_options {
          code
          label
          value
        }
      }
    }
  }
`;

import { ProductListFragment } from '@/services/graphql/fragments/product/productList.gql';
import { gql } from 'graphql-request';
import { amount } from '../../fragments/cart/cart.gql';

export const MiniCartFragment = gql`
  fragment MiniCartFragment on Cart {
    id
    total_quantity
    prices {
      grand_total {
        ${amount}
      }
      subtotal_excluding_tax {
        ${amount}
      }
      subtotal_including_tax {
        ${amount}
      }
      subtotal_with_discount_excluding_tax {
        ${amount}
      }
      applied_taxes {
        amount {
          ${amount}
        }
        label
      }
      discounts {
        amount {
          ${amount}
        }
        label 
      }
    }
  }
`;

import PersistanceStorage from '.';

const storage = new PersistanceStorage();
const key = 'cookie-consent';

export const getCookieConsent = () => {
  return storage.getItem(key);
};

export const setCookieConsent = (flag = true) => {
  storage.setItem(key, flag);
  return getCookieConsent();
};

export const getCookieConsentIsDeclined = () => {
  return getCookieConsent() == false;
};

export const removeCookieConsent = () => {
  storage.removeItem(key);
};

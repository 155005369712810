import HeaderTop from './HeaderTop';
import HeaderMenu from './HeaderMenu';
import { useEffect, useMemo, useState } from 'react';
import MiniCartPopUp from './MiniCart';
import HeaderProvider from '@/components/context/header';
import { useCartContext } from '@/components/context/CartContext';
import { useAppContext } from '@/components/context/AppContext';

const Header = () => {
  const [showMenu, handleMenuVisibility] = useState(false);
  const [showMiniBag, toggleMiniBag] = useState(false);
  const [{ isCartLoading }]: any = useCartContext();
  const [{ handleLoader, currencyList }] = useAppContext();

  const currency = useMemo(() => {
    if (currencyList && currencyList.exchange_rates) {
      const list = currencyList.exchange_rates;
      return {
        default: currencyList.default_display_currency_code,
        list,
        ...currencyList,
      };
    }
    return {};
  }, [currencyList]);

  const p = {
    showMenu,
    handleMenuVisibility,
    showMiniBag,
    toggleMiniBag,
    currency,
  };

  useEffect(() => {
    handleLoader(isCartLoading);
  }, [isCartLoading]);

  return (
    <HeaderProvider>
      <header onClick={(e) => e.stopPropagation()}>
        <HeaderTop {...p} />
        <HeaderMenu {...p} />
        <MiniCartPopUp {...p} />
      </header>
    </HeaderProvider>
  );
};

export default Header;

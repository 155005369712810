import cn from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import { NavItem } from './NavItem';
import styles from './HeaderMenu.module.scss';
import { useAppContext } from '@/components/context/AppContext';
import { useRef, useState } from 'react';
import SvgIcon from '@/components/atoms/icon/SvgIcon';
import Divider from '@/components/atoms/Divider';
import { useCallback } from 'react';

const SubCategoryMenu = ({
  handleMenuVisibility,
  c,
  toggleSubmenu,
  activeSubmenu,
  index,
  parentIndex,
  toggleParent,
  activeParentIndex,
  goToNextSlide,
  parentKey,
}) => {
  const handleMenuClick = useCallback(
    (e) => {
      e.preventDefault();
      goToNextSlide(parentKey);
      if (index !== activeSubmenu || parentIndex !== activeParentIndex) {
        toggleSubmenu(index);
        toggleParent(parentIndex);
      } else {
        toggleParent('');
        toggleSubmenu(-1);
      }
    },
    [activeSubmenu, index, parentIndex, activeParentIndex],
  );

  if (c.include_in_menu == 0) return null;

  return (
    <>
      <NavItem
        onClick={handleMenuClick}
        icon
        className={'ternary-color is-size-6 py-3 is-block'}
        {...{
          c,
          url: `/${c.url_path}${c.url_suffix}`,
        }}
        containerCN={'is-flex is-justify-content-center is-align-items-center'}
      >
        {c.children?.length && (
          <span onClick={handleMenuClick}>
            <SvgIcon
              className='mt-2'
              icon={activeSubmenu === index ? 'arrow-dropup' : 'arrow-dropdown'}
              size={'24'}
            />
          </span>
        )}
      </NavItem>
      {index === activeSubmenu &&
        activeParentIndex === parentIndex &&
        Array.isArray(c.children) &&
        c.children.map((s, k, a) => (
          <NavItem
            onClick={() => {
              handleMenuVisibility(false);
              toggleSubmenu(-1);
              toggleParent('');
            }}
            icon
            className={'ternary-color is-size-6 py-3 is-block'}
            key={k}
            {...{
              c: s,
              url: `/${s.url_path}${s.url_suffix}`,
            }}
          >
            {k == a.length - 1 && <Divider className='mt-1 mb-2' />}
          </NavItem>
        ))}
    </>
  );
};

const settings = {
  centeredSlides: true,
  slidesPerView: 2,
  resizeObserver: true,
};

export const SliderMenu = ({
  handleMenuVisibility,
  active,
  categories,
  setActive,
}) => {
  const [{ isMobile }] = useAppContext();
  const [activeSubmenu, toggleSubmenu] = useState(-1);
  const [activeParentIndex, toggleParent] = useState('');
  let swiper: any = useRef();

  const goToNextSlide = (index: number) =>
    swiper && swiper.current && swiper.current.slideTo(index);

  if (!isMobile) return null;

  return (
    <div className={cn('pt-5 mt-5 has-text-centered', styles.menuslider)}>
      <Swiper
        onSwiper={(e: any) => (swiper.current = e)}
        {...settings}
        containerModifierClass={'mobile-menu '}
      >
        {[...categories].map((c, i) => {
          const activeMenu = i === active ? 'is-active' : '';
          if (c.include_in_menu == 0) return null;

          return (
            <SwiperSlide key={i}>
              <NavItem
                onClick={() => {
                  setActive(active === i ? -1 : i);
                  handleMenuVisibility(false);
                }}
                className={cn(
                  activeMenu,
                  'is-size-4 is-uppercase has-text-weight-medium is-block mb-5 pt-3 pt-5',
                  styles.level1,
                )}
                {...{
                  c,
                  key: i,
                  url: `/${c.url_path}${c.url_suffix}`,
                }}
              >
                {Array.isArray(c.children) && !!c.children.length && (
                  <section>
                    {c.children.map((s: any, j: any) => (
                      <SubCategoryMenu
                        c={s}
                        key={j}
                        index={j}
                        parentIndex={c.name}
                        parentKey={i}
                        toggleParent={toggleParent}
                        activeParentIndex={activeParentIndex}
                        handleMenuVisibility={handleMenuVisibility}
                        toggleSubmenu={toggleSubmenu}
                        activeSubmenu={activeSubmenu}
                        goToNextSlide={goToNextSlide}
                      />
                    ))}
                  </section>
                )}
              </NavItem>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};
